body.fixed{

}
body.fixed .header{
    position: fixed;
    top: 0;
    width: 100%;
    background:#123969 !important
}

.api-container{
    background: transparent linear-gradient(19deg, #000428 0%, #000428 14%, #133C6D 100%) 0% 0% no-repeat padding-box;
    /* border-radius: 0px 0px 46px 46px; */
    padding-top: 72px;
    padding-bottom: 165px;
    margin-top: -72px;
    min-height: 100vh;
}

.api-leftmenu{
    background: #000428;
    width: 357px;
    height: 100vh;
    max-height: 100vh;
    padding-top: 50px;
    padding-right: 0;
    overflow-x: scroll;
    padding-bottom: 60px;
    
}
body.fixed .api-leftmenu{
    position: fixed;
    top: 0;
    margin-top: 72px;
}

.api-leftmenu h3{
    padding-left: 90px;
}

.api-leftmenu ul {
    list-style: none;
    padding-left: 0;
    padding-bottom: 50px;
}
.api-leftmenu ul li{
    
}

.api-leftmenu ul li a{
    text-align: left;
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
    color: #FFFFFF;
    padding-left: 90px;
    transition: 0.3s all;
    display: flex;
}
.api-leftmenu ul li a:hover, .api-leftmenu ul li a.active{
    color: var(--primary);
    text-decoration: none !important;
}

.api-container .api-middle{
    padding-top: 56px;
    padding-left: 30px;
}
body.fixed .api-container .api-middle{
    margin-left: 370px;
    margin-top: 40px;
}

.api-container h1{
    text-align: left;
    font-size: 48px;
    line-height: 55px;
    font-weight: 700;
    color: #FFFFFF;
}
.api-container h2{
    text-align: left;
    font-size: 36px;
    line-height: 44px;
    font-weight: 600;
    color: #FFFFFF;
}
.api-container h1 span, .api-container h2 span{
    color: var(--primary);
}
.api-container h3{
    text-align: left;
    font-size: 22px;
    line-height: 28px;
    font-weight: 600;
    color:var(--primary);
    margin-top: 20px;
}
.api-container h4{
    text-align: left;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    color: var(--primary);
}

.api-container p, .api-container a{
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
    color: #FFFFFF;
    margin: 10px 0;
}
.api-container p strong{
    font-weight:700;
}
.api-container a{
    text-decoration: none;
    color:var(--primary)
}
.api-container a:hover{
    text-decoration: underline !important;
}

.api-container section{
    margin-top:30px;
    margin-bottom:50px;
    overflow-x: auto;
}

.api-container table {
    overflow-y: auto
}

.api-container table {
    border-radius: 16px;
    width: 100%;
    border-collapse: collapse;
    background: #81818187;
    border: 0;
    padding: 0;
    margin-top: 10px;
}

.api-container table thead td,.api-container table thead tr {
    /* border: 1px solid #4f566b;
    padding: 15px;
    text-align: left;
    color: #9fa0ab;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px */

    border-bottom: 1px solid #adadad;
    padding: 15px;
    text-align: left;
    color: #ffffff;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    border-right: 1px solid #adadad;
    background: #0c26514a;
}
.api-container table thead td:last-child{
    border-right-color: transparent;
}

.api-container table thead td img,.api-container table thead tr img {
    width: 15px;
    margin-right: 15px
}

.api-container table th {
    background: #171b2f;
    border: 0;
    padding: 0
}

.api-container table tbody td,.api-container table tbody tr {
    padding: 15px;
    text-align: left;
    color: #ffffff;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    transition: 0.30s all;
}
.api-container table tbody tr:hover{
    background:#2c3e5d66;
}
.api-container table tbody td{
    border-bottom: 1px solid #adadad;
    border-right: 1px solid #adadad;
}
.api-container table tbody td:last-child{
    border-right-color: transparent;
}
.api-container table tbody tr:last-child td{
    border-bottom-color: transparent;
}

.api-container table tbody td span,.api-container table tbody tr span {
    border-radius: 2px;
    color: #090d1d;
    font-size: 12px;
    padding: 3px 10px;
    font-weight: 500;
    margin: 5px 8px 5px 0
}
.api-container table tbody td span,.api-container table tbody tr code{
    font-size: inherit;
    color: inherit;
    word-wrap: inherit;
    unicode-bidi: inherit;
    display: inherit;
    background: inherit;
}

.api-container table tbody td .mandatory,.api-container table tbody tr .mandatory {
    background: #d9d3e9
}

.api-container table tbody td .integer,.api-container table tbody tr .integer {
    background: #eeb3ef
}

.api-container table tbody td .string,.api-container table tbody tr .string {
    background: #f3f6fb
}

.api-container table tbody td .varchar,.api-container table tbody tr .varchar {
    background: #f9cad2
}

.api-container table tbody td .limit,.api-container table tbody tr .limit {
    background: #95d6f7
}

.api-container table tbody td .optional,.api-container table tbody tr .optional {
    background: #fff3e1
}

.api-container table tbody td .array,.api-container table tbody tr .array {
    background: #d0c4c4
}

.api-container table tbody td .float,.api-container table tbody tr .float {
    background: #f8c6c6
}

.api-container table tbody td .float.span-block,.api-container table tbody tr .float.span-block {
    display: inline-block
}

.tbl-wrp table thead td:first-child,.tbl-wrp table thead td:nth-child(2) {
    width: 14%!important
}

.tbl-wrp table thead td:nth-child(3) {
    width: 20%!important
}

.tbl-wrp table thead td:nth-child(4) {
    width: 22%!important
}

.tbl-wrp table thead td:nth-child(5) {
    width: 30%!important
}

.api-container table thead td:first-child,.api-container table thead td:nth-child(2) {
    width: 25%
}





.single-code-block, .api-container code {
    position: relative;
    margin: 30px 0;
    background: #000;
    border-radius: 30px;
}
.api-container code{
    color: #fff;
    padding: 5px;
    border-radius: unset;
    background: inherit;
}


.single-code-block .block-head {
    background: #273238;
    min-height: 50px;
    border-radius: 30px 30px 0 0;
    padding: 15px 15px 15px 30px;
    display: flex;
    justify-content: space-between;
}
.copy-btn {
    position: relative;
    cursor: pointer;
}
.copy-btn:hover{
    color:var(--primary) !important;
}
.copy-btn:before {
    content: url(https://cdn.gokwik.co/website/icons/copy.svg);
    position: absolute;
    left: -26px;
    top: 3px;
}

.single-code-block pre {
    color: #fff;
    padding: 30px;
    font-size: 20px;
    /* max-height: 500px;
    overflow: auto; */
}

.single-code-block h6 {
    color: #fff;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 0;
    padding-bottom: 0;
}


.codeblock {
    background: #000;
    margin: 30px 0;
    border-radius: 30px
}
.codeblock pre{
    color: #fff;
    padding: 30px;
    font-size: 20px;
}

.codeblock .codeblock-tabs {
    background: #273238;
    padding: 0 100px 0 15px;
    border-radius: 30px 30px 0 0;
    border: 0;
}
.codeblock .nav-tabs .nav-link,.codeblock .nav-tabs .nav-link:focus, .nav-tabs .nav-link:horizontal{
    border:0 !important;
    margin-bottom: 0;
    padding: 16px 5px;
    margin-right: 20px;
    cursor: pointer;
    font-size: 20px;
    color: #fff;
}
.codeblock .nav-tabs .nav-link.active{
    color: var(--primary);
}

.codeblock .tab-content{
    position: relative;
}

.codeblock .tab-content .copy-btn{
    position: relative;
    cursor: pointer;
    position: absolute;
    right: 18px;
    top: -40px;
}



@media only screen and (max-width:1100px) {
    .api-leftmenu{
        position: relative !important;
        background: none;
        min-height: auto;
        height: auto;
    }
    .api-leftmenu h3{
        padding-left: 20px;
    }
    .api-leftmenu ul li a{
        padding-left: 20px;
    }
    .api-container .api-middle{
        margin-left: 0 !important;
        width: 100%;
        margin-top: 0 !important;
        padding-top: 36px;
    }
}