


.blog-section-one{
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 79px 79px 0 0;
    position: relative;
    margin-top: -120px;
    padding-top: 55px;
    padding-bottom: 200px;
}

.blog-section-one .section{
    position: relative;
    top: -25px;
    width: 964px;
    margin: 0 auto;
    border-radius: 86px 106px 0 0;
    padding-bottom: 68px;
}


.sucess-story-top345{
    top:-345px
}


.blog-section-one .items{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    gap: 60px;
    row-gap: 60px;
    margin-top: 40px;
    margin-bottom: 70px;
}

.blog-section-one .items .item{
    text-align: left;
    align-self: baseline;
    max-width: 476px;
    height:auto;
    cursor:pointer;
}
.blog-section-one .items .item img{
    width: 100%;
    /* filter: grayscale(100%); */
    border: 2px solid #FFFFFF;
    border-radius: 20px;
    max-width:100%;
}
.blog-section-one .items .item:hover img{
    filter: none;
    cursor:pointer;
}
.blog-section-one .items .item p.category{
    text-align: left;
    font-size: 12px;
    font-weight: 300;
    line-height: 22px;
    color: #F9D6A3;
    margin-top: 10px;
}
.blog-section-one .items .item p.category span{
    color: var(--font-one);
}
.blog-section-one .items .item p.title{
    text-align: left;
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0px;
    color: var(--font-one);
    margin-top: 5px;
    margin-bottom: 5px !important;
}
.blog-section-one .items .item p.title span, .blog-section-one .items .item p.title strong{
    color: var(--primary);
}

.blog-section-one .items .item  a{
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    color: #fff;
    padding-left: 0px;
}
.blog-section-one .items .item  a:hover{
    color: var(--primary);
}

@media (max-width: 769px) {
    .blog-section-one .items{
        row-gap: 30px;
        margin-bottom: 30px;
    }
}




.blog-section-details{
    background: transparent linear-gradient(43deg, #001B34 0%, #000428 36%, #133C6D 100%) 0% 0% no-repeat padding-box;
    padding-top: 112px;
    padding-bottom: 110px;
    margin-top: -72px;
}

.blog-content{
    max-width: 1004px;
    margin: 0 auto;
    background: transparent linear-gradient(0deg, #001B34 0%, #000428 0%, #000428 38%, #133C6D 100%) 0% 0% no-repeat padding-box;
    border-radius: 46px 40px 46px 46px;
    padding: 50px;
}

.blog-content p.category{
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: var(--primary);
    margin-bottom: 5px !important;
}
.blog-content p.category span{
    color: var(--font-one);
}
.blog-content .featured-image{
    max-width: 100%;
    border-radius: 20px;
    margin-bottom: 40px;
    margin-top: 20px;
}
.blog-content h1{
    font-size: 40px;
    font-weight: 700;
    line-height: 58px;
    letter-spacing: 0px;
    /* text-transform: capitalize; */
    
}
.blog-content h2{
    font-size: 36px;
    font-weight: 600;
    line-height: 44px;
    text-transform: capitalize;
    color: var(--font-one);
    padding-top: 10px;
    margin-bottom: 20px;
}
.blog-content h1 span,.blog-content h2 span, .blog-content p span,
.blog-content h1 strong,.blog-content h2 strong, .blog-content p strong{
    
    font-weight: inherit;
}

.blog-content p, .blog-content a{
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 20px !important;
}
.blog-content p{
    text-align: justify;
}
.blog-content a{
    color: var(--primary);
    text-decoration: underline !important;
}



.blog-content ul {
    list-style: none;
    margin-left: 0;
    /* padding-left: 0; */
}
.blog-content ul li{
    /* /* font-size: 18px; */
    font-weight: 300; 
    line-height: 28px;
    color: #FFFFFF;
}  

.blog-content ul li::before {
    content: "\2022";
    color: var(--primary);
    font-weight: bold;
    display: inline-block;
    width: 6px;
    height: 6px;
    margin-left: 0;
    padding-right: 12px;
}

.blog-content ol {
    list-style: auto;
    margin-left: 0;
    padding-left: 0;
}
.blog-content ol li {
    color: #fff;
    /* font-size: 28px; */
    /* font-weight: 600; */
    /* line-height: 42px; */
     margin-bottom: 20px;
    margin-left: 28px;
}
.blog-content ol li p{
    margin-top: 10px;
    margin-bottom: 10px !important;
}





.blog-content ul.social{
    list-style:none;
    display: flex;
    gap: 15px;
    margin: 0;
}
.blog-content ul.social li,.blog-content ul.social li a{
    list-style: none;
    font-size: 22px;
    padding: 0;
    line-height: 1;
    cursor: pointer;
    align-self: center;
}
.blog-content ul.social li a{
    color: var(--font-one);
}
.blog-content ul.social li::before{
    content: '';
    padding: 0;
}
.blog-content ul.social li i{
    transition: 0.3ms;
    /* color:var(--font-one); */
}
.blog-content ul.social li:hover i{
    color: var(--primary);
}
.blog-content img{
    max-width: 100%;
    height: auto;
}



.blog-share{
    background: #C8DFFF 0% 0% no-repeat padding-box;
    border-radius: 10px;
    height: 67px;
    display: flex;
    justify-content: center;
    column-gap: 30px;
    margin-top: 50px;
}
.blog-share p{
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    color: #000C4A;
    line-height:67px;
}
.blog-share ul.social li{
    color: #000C4A;
}
.detail-page-form .field-error {
    border: 1px solid #ff0000 !important;
}

@media (max-width: 900px) {
    .blog-content{
        max-width:100%;
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media (max-width: 600px) {
    .blog-share{
        height: auto;
        flex-wrap: wrap;
        padding: 10px;
    }
    .blog-share p {
        line-height: 24px;
    }
}



@media (max-width: 575px) {
    .blog-section-one{
        padding-top: 0px;
    }
}

.blog-section-details .playbook-download {
    min-width: 130px;
    padding: 0px 20px;
    border-color: transparent;
    background: transparent linear-gradient(319deg, #133D6E 0%, #010723 100%) 0% 0% no-repeat padding-box;
    color: var(--font-one);
    min-height: 45px;
    margin-top: 20px;
    border-radius: 8px;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 90px;
    font-weight: 600;
    transform: translate(0px);
    cursor: pointer;
}

.blog-section-details .playbook{
display: flex;
align-items: center;
}

.playbook-right{
    padding: 0 30px;
    }
.playbook-right h1{
    color: #f09819;
    }
.playbook-right span{
    color: #fff!important;
 }
.detail-page-form{
    align-items: center;
    gap: 15px;
    padding-top: 18px;
    row-gap: 20px;
    display: flex;
    padding: 30px 20px;
 }
.blog-content button {
        background: transparent linear-gradient(180deg,#0c3c67,#00213d) 0 0 no-repeat padding-box;
        box-shadow: none;
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        line-height: 28px;
 }
 .playbook-content button:hover{
    color: #fff;
 }
    .detail-page-form input {
    border: 0;
    border-radius: 6px;
    height: 36px;
    padding-left: 12px;
}
    .form-item {
    background: transparent linear-gradient(183deg,#ff9700,#fdd851) 0 0 no-repeat padding-box;
    border-radius: 40px 40px 0 0;
    margin: 20px auto -30px;
    max-width: 1004px;
    min-height: 110px;
}
    .verification-code-inputs{
        gap: 10px;
        display: flex;
        padding: 30px 20px;
        align-items: center;
        justify-content: center;
    }
    .verification-code-inputs input[type=text] {
        border: none;
        border-radius: 6px;
        /* width: 46px; */
        height: 46px;
        padding: 10px;
        /* text-align: center; */
        display: inline-block;
        box-sizing: border-box;
    }
    .verification-code-inputs h3{
        font-size: 26px;
        line-height: 44px;
        font-weight: 600;
        color: #000C4A;
    }
    @media (max-width: 769px){
 .detail-page-form {
    flex-direction: column;
    padding: 20px;
    text-align: center;
    margin-bottom: 30px;
}
.verification-code-inputs{
    flex-direction: column;
}
}
@media screen and (max-width: 576px){
.blog-section-details .playbook {
    flex-direction: column;
    height: auto;
    row-gap: 10px;
}
}