.product-explainer-section-one{
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 79px 79px 0 0;
    position: relative;
    margin-top: -120px;
    padding-top: 80px;
    padding-bottom: 200px;
}

.product-explainer-section-one .tab-one{
    max-width: 1122px;
}



.product-explainer-section-one .items{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    gap: 60px;
    row-gap: 60px;
    margin-top: 40px;
    margin-bottom: 70px;
}
.product-explainer-section-one .items .item{
    text-align: left;
    align-self: baseline;
    max-width: 476px;
    height:auto;
    cursor:pointer;
    position: relative;
}
.product-explainer-section-one .items .item .play-icon{
    position: absolute;
    width:20%;
    max-width: 64px;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 30%;
    opacity: 0.75;
    transition: all 500ms;
    z-index:9;
    cursor: pointer;
}
.product-explainer-section-one .items .item .play-icon img{
    border: 0;
    border-radius: 0;
    max-width: 100%;
}
.product-explainer-section-one .items .item:hover .play-icon{
    opacity: 1;
}
.product-explainer-section-one .items .item img{
    width: 100%;
    border-radius: 15px;
    border: 2px solid #FFFFFF;
    border-radius: 40px 40px 40px 20px;
}
.product-explainer-section-one .items .item:hover img{
    filter: none;
    cursor:pointer;
}

.product-explainer-section-one .items .item p.title{
    text-align: left;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0px;
    color: var(--font-one);
    margin-top: 15px;
    margin-bottom: 5px !important;
}
.product-explainer-section-one .items .item p.title span{
    color: var(--primary);
}


@media (max-width: 769px) {
    .product-explainer-section-one .items{
        row-gap: 30px;
        margin-bottom: 30px;
    }
    .product-explainer-section-one .items .item .play-icon{
        top:25%;
    }
}
