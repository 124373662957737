.top-section{
    margin-bottom: 70px;
    padding: 0 40px;
}
.top-section .toplogo{
    float: right;
}
.kcheckout-client {
    background: var(--secondary);
}
.bannerimg img{
    margin-top: 40px;
}

.kcheckout-client .client_box {
    background: #fff;
    border-top-left-radius: 80px;
    border-top-right-radius: 80px;
    margin: -60px auto 0;
    max-width: 87%;
    padding: 45px 0 40px;
    position: relative;
}

.kcheckout-client .grid {
    display: flex;
    flex-wrap: wrap;
    gap: 60px;
    justify-content: center;
    margin: 15px 0 95px 0;
    overflow: hidden;
    padding: 10px 20px;
    align-items: center;
}

.kcheckout-client .heading h5 {
    color: #444444;
    font-size: 28px;
    text-align: center;
}


.kcheckout-client .heading h2 {
    color: #000;
    font-size: 44px;
    text-align: center;
    font-weight: 700;
}

.shoppers-section-three {
    background: linear-gradient(180deg, #0C3C67 0, #00213D 31.03%, #000E1A 100%);
    border-top-left-radius: 80px;
    border-top-right-radius: 80px;
    margin-top: -80px;
    padding-bottom: 150px;
    overflow: hidden;
    position: relative;
}

.shoppers-section-three .section-heading {
    font-size: 28px;
    font-weight: 400;
}

.shoppers-section-three .content {
    text-align: center;
}

.shoppers-section-three .content h4 {
    font-size: 72px;
    color: #F89B15;
    font-weight: 800;
}

.shoppers-section-three .item {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 0 150px;
}

.drive-section-five {
    background: linear-gradient(180deg, #0C3C67 0, #00213D 31.03%, #000E1A 100%);
    border-top-left-radius: 80px;
    border-top-right-radius: 80px;
    margin-top: -80px;
    padding-bottom: 100px;
    overflow: hidden;
    position: relative;
}

.drive-section-five .sec-width {
    background: none;
    width: 1200px;
    border-top-left-radius: 40px;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 40px;
    border-bottom-left-radius: 40px;
    min-height: 47%;
    padding-top: 50px;
    margin: 0 auto;
    max-width: 100%;
    top: 0;
}

.drive-section-five .sec-width .left-side {
    padding: 0 30px;
}

.drive-section-five .sec-width .left-side .title h3 {
    font-size: 36px;
    line-height: 42px;
    font-weight: 600;
    padding-top: 0;
    margin-top: 0;
}

.drive-section-five .sec-width .right-side {
    text-align: right;
}

.drive-section-five .sec-width .left-side .descr {
    font-size: 22px;
    font-weight: 300;
}

.drive-section-five .sec-width .left-side .descr p {
    font-size: 22px;
    font-weight: 300;
    line-height: 32px;
    margin-bottom: 30px !important;
}

.checkout-conversion-section-four {
    background: linear-gradient(180deg, #E8F2FF 0, #7EB4FF 100%);
    margin-top: -80px;
    padding-bottom: 100px;
    overflow: hidden;
    position: relative;
}

.checkout-conversion-section-four .section-width {
    background: none;
    width: 1000px;
    padding-top: 50px;
    margin: 0 auto;
    max-width: 100%;
    top: 0;
}

.checkout-conversion-section-four .left-side .title h3 {
    color: #03306E;
    font-size: 44px;
    font-weight: 700;
    line-height: 50px;
}

.checkout-conversion-section-four button {
    background: transparent linear-gradient(180deg, #0C3C67, #00213D) 0 0 no-repeat padding-box;
    border-color: transparent;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    height: 50px;
    margin-bottom: 40px;
    margin-top: 20px;
    -webkit-transform: translate(0);
    transform: translate(0);
    padding: 0 15px;

}

.merchants-section-six {
    background: linear-gradient(380deg, #000E1A 0, #00213D 100%, #0C3C67 100%);
    margin-top: -80px;
    padding-bottom: 100px;
    overflow: hidden;
    position: relative;

}

.merchants-section-six .about-box {
    background: #012543;
    border-radius: 80px !important;
    /* height: 520px; */
    height: auto;
    margin: 0 auto;
    padding: 20px 10px 20px 10px;
    text-align: center;
    margin: 20px;
}

.merchants-heading {
    color: var(--font-one);
    font-size: 44px;
    font-weight: 400;
    line-height: 58px;
    padding: 50px 0;
    text-align: center;
    max-width: 700px;
    margin: 0 auto;
}

.merchants-heading span {
    color: var(--primary);
    font-weight: 700;
}

.merchants {
    padding: 0 50px;

}

.about-box .box-img {
    height: 50px;
}

.merchants .profile-desp {
    color: #C2C2C2;
    font-size: 20px;
    line-height: 30px;
    margin: 20px;
    /* height: 290px; */
    height: auto;
}

.kwik-adv {
    text-align: center;
    margin-top: 70px;
    margin-bottom: 70px;
}

.kwik-content h4 {
    color: #F89B15;
    font-size: 36px;
    font-weight: 700;
    margin: 0;

}

.kwik-content p {
    color: #FFF;
    font-size: 36px;
}

.kwik-content {
    margin-bottom: 35px;
}

.kwik-adv a {
    background: transparent linear-gradient(180deg, #0C3C67, #00213D) 0 0 no-repeat padding-box;
    border-color: transparent;
    border-radius: 5px;
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    height: 50px;
    
    -webkit-transform: translate(0);
    transform: translate(0);
    padding: 13px 15px;
}

.banner-home .b-heading {
    color: #000;
    font-size: 66px;
    font-weight: 800;
    line-height: 72px;
}

.banner-home .left {
    margin-top: 0;
}

.check-banner-info {
    background-color: #F39A32;
    padding: 30px;
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-left: -115px;
    padding-left: 65px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.check-banner-info p {
    color: #fff;
    font-weight: 300;
    margin-left: 10px;
    font-size: 17px;
}

.check-name {
    display: flex;
    font-size: 20px;
    font-weight: 500;
    margin-top: 12px;
}
.banner-home .kwickcheckc .button-cta {
    min-width: 130px;
    padding: 0px 20px;
    border-color: transparent;
    background: transparent linear-gradient(319deg, #133D6E 0%, #010723 100%) 0% 0% no-repeat padding-box;
    color: var(--font-one);
    min-height: 55px;
    margin-top: 50px;
    border-radius: 8px;
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 50px;
    font-weight: 600;
    transform: translate(0px);
    cursor: pointer;
}

.banner-checkout {
    background: var(--primary) url("./kwikcheckout-background.png");
    margin-top: 0;
    padding-top: 30px;
}

.merchants-section-twelve {
    background-image:url("../../../public/assets/images/kwikcheckout/group.svg");
    /* background: linear-gradient(190deg, #F89B15 0, #F3AD47 100%, #F6E6D0 100%); */
    margin-top: -80px;
    padding-bottom: 80px;
    overflow: hidden;
    position: relative;
    background-size: cover;
    background-position: center;

}
.merchants-section-twelve .kwik-content h4{
    color: #000;
    font-size: 44px;
    font-weight: 700;
    margin-bottom: 10px;
}


.merchants-section-twelve  .kwik-content p {
    color: #000;
    font-size: 36px;
}

.merchants-section-twelve  .kwik-content {
    margin-bottom: 35px;
}
.robust .protect .protect-align{
align-items: center;
}
.robust .protect {
    background: transparent linear-gradient(181deg, #ff9700, #fdd851) 0 0 no-repeat padding-box;
    border-radius: 30px;
    font-size: 20px;
    font-weight: 300;
    padding: 30px;
    line-height: 28px;
    margin: 50px auto 0;
    position: relative;
}
.robust .protect h4{
    color: #000;
    font-size: 36px;
    font-weight: 700;
}
.robust .protect p{
    color: #000;
    font-weight: 500;   
    font-size: 24px; 
}
.robust a {
    background: transparent linear-gradient(180deg, #0C3C67, #00213D) 0 0 no-repeat padding-box;
    border-color: transparent;
    border-radius: 5px;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    height: 50px;
    transform: translate(0);
    padding: 13px 15px;
}
@media screen and (max-width: 320px) {   
    .check-banner-info {
        margin-left: -50px;
        display: block;
    }

    .merchants {
        padding: 0 10px;
    }
    .top-section .toplogo{
        float: left;
        margin-top: 30px;
    }
    .checkout-conversion-section-four .title{
        margin-left: 20px;
    }
    .checkout-conversion-section-four .descr{
        margin-left: 20px;
    }
    .shoppers-section-three .content {
       margin-bottom: 40px;
    }
    .kcheckout-client .heading h5{
        font-size: 24px;
     }
     .drive-section-five .sec-width .left-side .descr p{
        font-size: 18px;
     }
     .drive-section-five .sec-width .left-side .descr{
        font-size: 18px;
     }
     .checkout-conversion-section-four .left-side .title h3{
        font-size: 36px;
     }
     .merchants-heading{
        font-size: 36px;
        line-height: 45px;
     }
     .kwik-content p{
        font-size: 18px;
     }
     .robust .protect .content {
        margin-bottom: 40px;
     }
}

@media only screen and (min-width:280px) and (max-width:768px) {
    .check-banner-info {
        margin-left: -50px;
        display: block;
    }

    .merchants {
        padding: 0 10px;
    }
    .top-section .toplogo{
        float: left;
        margin-bottom: 30px;
    }
    .checkout-conversion-section-four .title{
        margin-left: 20px;
    }
    .checkout-conversion-section-four .descr{
        margin-left: 20px;
    }
    .shoppers-section-three .content {
        margin-bottom: 40px;
     }
      .kcheckout-client .heading h5{
        font-size: 24px;
     }
     .drive-section-five .sec-width .left-side .descr p{
        font-size: 18px;
     }
     .drive-section-five .sec-width .left-side .descr{
        font-size: 18px;
     }
     .checkout-conversion-section-four .left-side .title h3{
        font-size: 36px;
     }
     .merchants-heading{
        font-size: 36px;
        line-height: 45px;
     }
     .kwik-content p{
        font-size: 18px;
     }
     .robust .protect .content {
        margin-bottom: 40px;
     }
     .banner-home .display-flex {
        display: flex !important;
        flex-flow: column-reverse;
    }
    .animate{
        animation: none;
    }
}


@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .check-banner-info {
        margin-left: -50px;
    }
   
}
@media only screen and (min-width: 1280px) {
.merchants .profile-desp{
    height: 290px;
}
}