.section-one-banner {
  padding-top: 112px;
  padding-bottom: 110px;
  margin-top: -72px;
  background: var(--primary) url(/public/assets/images/glory/banner-bg.svg);
  background-size: cover;
  min-height: 754px;
  background-position: right;
}

.section-one-banner .home-p {
  color: #000 !important;
  font-size: 22px;
  line-height: 30px;
  font-weight: 500;
}
.section-one-banner .button-cta {
  min-width: 130px;
  padding: 0px 20px;
  border-color: transparent;
  background: transparent linear-gradient(319deg, #133D6E 0%, #010723 100%) 0% 0% no-repeat padding-box;
  color: var(--font-one);
  min-height: 45px;
  margin-top: 20px;
  border-radius: 8px;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 90px;
  font-weight: 600;
  transform: translate(0px);
  cursor: pointer;
}
.section-one-banner h1 {
  color: #000;
  font-size: 68px;
  font-weight: 700;
  line-height: 72px;
}


/* section two */
.glory-section-two {
  background: linear-gradient(0deg, #000e1a 0%, #00213d 64%, #0c3c67 100%);
  border-top-left-radius: 80px;
  border-top-right-radius: 80px;
  padding-bottom: 100px;
  overflow: hidden;
  position: relative;
  margin-top: -100px;
}
.glory-section-two .sec-width {
    background: linear-gradient(0deg, rgba(4, 42, 74, 1) 0%, rgba(0, 14, 26, 1) 100%);
    border-radius: 80px;
    margin: 0 auto;
    padding: 40px 60px;
    width: 1200px;
    margin-bottom: 100px;
    max-width: 100%;
    border: 1px solid #FFD700;
}

.glory-section-two .sec-width .left-side p{
  font-size: 28px;
  line-height: 40px;
}
.glory-section-two .inner {
  text-align: center;
  margin-bottom: 50px;
  padding: 0 250px;
  margin-top: 50px;
}
.glory-section-two .inner h2 {
  color: #F79D1C;
  font-size: 40px;
}
.glory-section-two .inner p {
  font-size: 28px;
}

.eligibility-criteria .eligibility-section{
  margin: auto!important;
  max-width: 1100px;
}
.eligibility-criteria{
  margin-bottom: 200px;
}
.eligibility-criteria .back-left-bg{
  position: absolute;
  left: -150px;
  bottom: 193px;
}
.eligibility-criteria .back-right-bg{
  position: absolute;
  left: 685px;
  bottom: 193px;
}
.eligibility-criteria .item {
  background: #0000 linear-gradient(180deg,#0C3C67,#00213D) 0 0;
  border-radius: 80px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0 auto;
  max-width: 500px;
  min-height: 342px;
  padding: 30px 60px;
  row-gap: 30px;
  text-align: center;
  margin-bottom: 20px;
  border: 1px solid;
  z-index: 9;
}
.eligibility-criteria .item h4{
  font-size: 30px;
  color: #F89B15;
  font-weight: 600;
}
.eligibility-criteria .item p{
  font-size: 22px;
}


/* section three start */
.growth-faq .faq-tab .accordion-body ul li{

  list-style: disc;
}

.list-style{
list-style: circle!important;}

@media only screen and (max-width: 480px) {
  .faq-t {
    display: none;
  }
  .question {
    padding-right: 0px;
  }
  main {
    padding: 10px;
  }
  .answer {
    margin-bottom: 30px;
    padding-left: 0px;
    padding-right: 0px;
  }
}

/* Desktops and laptops ----------- */
@media only screen and (min-width : 1024px) {
  .glory-section-two .sec-width .right-img img{
    margin-left: -85px;
  }
  .glory-section-two .sec-width .left-side p{
    margin-left: -95px;
  }
}
/* iPads (portrait and landscape) ----------- */
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) {
  .glory-section-two .inner {
    text-align: center;
    margin-bottom: 50px;
    padding: 5px;
    margin-top: 50px;
  }
  .glory-section-two .sec-width .right-img img{
  margin-left: -165px;
}
.glory-section-two .sec-width .left-side p{
  margin-left: -35px;
}
}
/* Large screens ----------- */
@media only screen and (min-width : 1824px) {
  .section-one-banner h1 {
    color: #000;
    font-size: 73px;
    font-weight: 700;
    line-height: 72px;
}
.section-one-banner .home-p {
  color: #000 !important;
  font-size: 32px;
  line-height: 45px;
  font-weight: 400;
}
.eligibility-criteria .back-right-bg {
  position: absolute;
  left: 990px;
  bottom: 193px;
}
.eligibility-criteria .back-left-bg {
  position: absolute;
  left: 80px;
  bottom: 193px;
}
.glory-section-two .sec-width .right-img img{
  margin-left: -85px;
}
.glory-section-two .sec-width .left-side p{
  margin-left: -95px;
}
}


@media screen and (min-width: 1401px) {
  .container-fluid {
    max-width: 1650px;
  }
  .glory-section-two .sec-width .right-img img{
    margin-left: -85px;
  }
  .glory-section-two .sec-width .left-side p{
    margin-left: -95px;
  }
  
}

@media only screen and (min-width:280px) and (max-width:768px) {
  .section-one-banner .display-flex {
    display: flex!important;
    flex-flow: column-reverse;
}
.section-one-banner .left{
  padding: 0 20px!important;
}
.glory-section-two .sec-width {
  padding: 10px 20px;
}
.glory-section-two .sec-width .left-side p{
  text-align: center;
}
.glory-section-two {
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
}

.glory-section-two .inner p {
  font-size: 22px;
}
.glory-section-two .inner h2 {
  color: #F79D1C;
  font-size: 30px;
}
.glory-section-two .sec-width .left-side h3 {
  font-size: 28px;
  text-align: center;
}
.contact-section-three{
  border-radius: 3px 80px 20px 20px;
}
.glory-section-two .sec-width img{
  max-width: 100%;
}
.glory-section-two .inner {
  text-align: center;
  margin-bottom: 50px;
  padding: 5px;
  margin-top: 50px;
}
}