/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/

footer {
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    background: transparent linear-gradient(180deg, #133C6D 0%, #000428 100%) 0% 0% no-repeat padding-box;
    border-radius: 40px 40px 0px 0px;
    padding-left: 80px;
    padding-right: 80px;
}

footer .foot-top {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

footer .foot-top img {
    width: 180px;
}

footer p {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
    font-weight: 300;
}

footer .header-social-links{
    padding-left: 2rem;
    display: flex;
    gap: 30px;
    align-items: center;
}
footer .header-social-links a img{
    width:auto;
}


footer ul.social{
    list-style:none;
    display: flex;
    gap: 15px;
    margin: 0;
}
footer ul.social li{
    list-style: none;
    font-size: 28px;
    padding: 0;
    line-height: 1;
    cursor: pointer;
    align-self: center;
}
footer ul.social li a{
    color:inherit;
}
footer ul.social li::before{
    content: '';
    padding: 0;
}
footer ul.social li i{
    transition: 0.3ms all;
}
footer ul.social li:hover i{
    color: var(--primary);
}



footer .email{
    gap: 10px;
    display: flex;
}
footer .email a {
    color: var(--font-one);
    text-decoration: underline !important;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
}

footer address{
    gap: 10px;
    display: flex;
}

footer address p {
    font-size: 14px;
    line-height: 24px;
}

footer .addresssize img{
    height: 24px;
}


footer .card-payment{
    padding-bottom: 20px; 
}

footer .menu{
    padding-left: 2rem;
}
footer .menu h3{
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
}
footer .menu ul {
    list-style: none;
    padding-left: 0;
}
footer .menu ul li{
    margin-bottom: 6px;
}

footer .menu ul a {
    color: var(--font-one);
    font-size: 16px;
    line-height: 20px;
    font-weight: 300;
    color: #E8F2FF;
}

footer .menu ul a:hover {
    color: var(--primary);
    font-weight: 500;
}




.foot-bottom {
    background: #001324;
    height: 60px;
    text-align: center;
}

.foot-bottom p {
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    padding-top: 20px;
    line-height: 20px;
}





@media (max-width: 767px) {
    footer{
        padding-left: 10px;
        padding-right: 10px;
    }
    footer ul.social{
        padding-left: 0;
        margin-top: 30px;
    }
    footer .menu{
        padding-left: 1rem;
    }
}


