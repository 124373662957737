.banner-four {
    /* background: var(--primary) !important; */
    padding-top: 112px;
    padding-bottom: 80px;
    margin-top: -72px;
    background: var(--primary) url("./bg1.png");
    background-size: cover;
    min-height: 572px;
    cursor: pointer;
}

.banner-four .slick-wrapper{
    max-width: 1004px !important;
    margin: 0 auto;
    z-index: 999;
    background: #08122E 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #00000029;
    border-radius: 20px;
   
}
.banner-four .slick-wrapper .slick-list{padding-top: 0;}
.banner-four .slick-wrapper .slick-dots{
    bottom: 8px;
}
.banner-four .slick-wrapper .slick-dots li button:before{
    border: 1px solid #F89B15;
    width: 10px;
    height: 10px;
    line-height: 10px;
    border-radius: 50px;
    color: transparent;
}
.banner-four .slick-wrapper .slick-dots li.slick-active button:before{
    color: #F9D44E;
    opacity: 1;
    border-color: transparent;
}


.banner-four .wrapper{
    width: 100%;
    height: 380px;
    margin: 0 auto; 
}
.banner-four .wrapper .left{
    padding-left: 70px !important;
    margin-top: 30px;
}
.banner-four .wrapper h1{
    color: #fff;
    font-size: 40px;
    font-weight: 700;
    line-height: 44px;
    margin-bottom: 15px;
    margin-top: 15px;
}

.banner-four .wrapper img{
    border-radius: 15px;
    margin: 0 auto;
    align-self: center;
    max-width: 430px;
    display: flex;
    justify-content: center;
}
.banner-four .rightbox{
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.banner-four .wrapper img.video{
    cursor: pointer;
}
.banner-four .wrapper img:hover{
    transform:none;
}
.banner-four .wrapper p{
    color: #FFFFFF;
}
.banner-four .wrapper p.page-heading{
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
}
.banner-four .wrapper p.category {
    text-align: left;
    font-size: 12px;
    font-weight: 300;
    line-height: 22px;
    color: #F9D6A3;
    margin-top: 20px;
}
.banner-four .wrapper p.category span {
    color: var(--font-one);
}


.banner-four .wrapper a {
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    color: #fff;
    padding-left: 0px;
}
.banner-four .wrapper a:hover {
    color: var(--primary);
}

.banner-four .wrapper h1 span{
    color: var(--primary);
}


@media (max-width: 768px) {
    .banner-four .wrapper{
        height: auto;
    }
    .banner-four .wrapper img{
        padding:10px;
        max-width: 100%;
        margin-top: 0;
        margin-bottom: 30px;
    }
    
    .banner-four .wrapper .left{
        margin-top: 10px;
        padding-left: 20px !important;
        padding-right: 20px;
        text-align: center; 
    }
    .banner-four .wrapper .right{
        margin-bottom: 10px;
    }
    .banner-four .wrapper p.category{
        text-align: center;
    }
    .banner-four .wrapper h1{
        font-size: 30px;
        line-height: 42px;
    }
    .banner-four .wrapper p{
        font-size: 16px !important;
        line-height: 24px !important;
    }
    
}
