.subscribe{
    background: transparent linear-gradient(183deg, #FF9700 0%, #FDD851 100%) 0% 0% no-repeat padding-box;
    border-radius: 40px 40px 0px 0px;
    min-height: 150px;
    max-width: 1004px;
    margin: 0 auto;
    margin-bottom: -30px;
    justify-content: center;
    display: flex;
    margin-top: 20px;
}

.subscribe .popup-item{
    display: flex;
    justify-content: center;
    gap: 20px;
    row-gap: 20px;
    align-items: center;
    padding-top: 0;
}
.subscribe .item{
   
    text-align: center;
    padding-top: 10px;
}

.subscribe h3{
    font-size: 28px;
    line-height: 44px;
    font-weight: 600;
    color: #000C4A;
}

.subscribe input{
    color: #000C4A;
    height: 36px;
    background: #FFFFFF;
    border-radius: 6px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    border: 0;
    padding-left: 12px;
    max-width: 100%;
}

.subscribe button{
    font-size: 16px;
    line-height: 28px;
    font-weight: 600;
    color: #FFFFFF;
    background: transparent linear-gradient(180deg, #0C3C67 0%, #00213D 100%) 0% 0% no-repeat padding-box;
    border-radius: 6px;
    height: 36px;
    box-shadow: none;
    border: 0;
    width: 80px;
}

@media (max-width: 769px) {
    .subscribe .item{
        flex-direction: column;
        padding: 20px;
        text-align: center;
        margin-bottom: 30px;
    }
}

@media (max-width: 769px) {
    .subscribe .popup-item{
        flex-direction: column;
        padding: 20px;
        text-align: center;
        margin-bottom: 30px;
    }
}