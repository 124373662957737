
.bg-light{
    background: transparent linear-gradient(180deg, #133C6D 0%, #000428 64%, #000428 100%) 0% 0% no-repeat padding-box;
}
.bg-dark{
    background: transparent linear-gradient(199deg, #133C6D 0%, #000428 29%, #000428 100%) 0% 0% no-repeat padding-box;
}
.bg3{
    background: transparent linear-gradient(183deg, #00315D 0%, #000428 50%, #001B33 100%) 0% 0% no-repeat padding-box;
}




.partners-section-one{
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 79px 79px 0;
    position: relative;
    margin-top: -25px;
    padding-bottom: 20px;
}

.partners-section-one .section{
    position: relative;
    top: -25px;
    width: 964px;
    margin: 0 auto;
    border-radius: 86px 106px 40px 40px;
    padding-bottom: 68px;
    max-width: 100%;
}

.partners-section-one .section.secound{
    margin-top:-135px !important;
}
.partners-section-one .section .sub-section-header{
    margin-bottom: 0px;
}

.partners-section-one .section .and-more{
    text-align: center;
    padding-bottom: 135px;
}
.partners-section-one .section .and-more{
    font-size: 24px;
    line-height: 30px;
    font-weight: 300;
    letter-spacing: 0px;
    text-align: center;
    letter-spacing: 0px;
    color: #F9D6A3;
    opacity: 0.88;
}

.partners-section-one .section .items{
    display: flex;
    gap: 40px;
    row-gap: 0px;
    /* width: 700px; */
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    max-width: 100%;
}

@media (max-width: 964px) {
    .partners-section-one .section .logos { grid-template-columns: repeat(2, 1fr);width: auto; }
}
@media (max-width: 482px) {
    .partners-section-one .section .logos { grid-template-columns: repeat(1, 1fr);width: auto; }
}

.partners-section-one .section .items .item{
    text-align: center;
    align-self: baseline;
}
.partners-section-one .section .items .item img{

}



.partners-sucess-story-top{
    top: -180px;
}
.partners-network-effect-top{
    margin-top: -135px;
}


@media (max-width: 900px) {
    .partners-section-one .section .items{
        max-width: 600px;
    }
}

@media (max-width: 480px) {
    .partners-section-one .section .items{
        gap:10px;
    }
}