.banner-two .fixed-right-img{
    position: absolute;
    right: -8px;
    top: 150px;
}

.banner-two{
  min-height: 754px;
}



@media (max-width: 767px) {
  .banner-two .fixed-right-img{
      position:unset;
      top:unset;
      right: unset;
      margin-bottom: 40px;
  }
}