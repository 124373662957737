.flex{
    display: flex;
}
.about.wrap-1{
    /* background: transparent linear-gradient(196deg, #F89B15 0%, #F3AD47 36%, #F6E3CA 100%, #F6E6D0 100%) 0% 0% no-repeat padding-box !important; */
}
.about.wrap-1 img{
    max-width: 531px;
}

.about.wrap-1 .left {
    margin-top: 70px;
}

.section-one{
    background: var(--primary);
    min-height:350px;
    height:auto;
    position: relative;
}
.section-one-wrapper{
    position: relative;
    margin-top: -400px;
    z-index: 99;
}
.section-one-wrapper .inner{
    width: 1000px;
    max-width: 100%;
    height:auto;
    margin:0 auto;
    background: transparent linear-gradient(183deg, #00315D 0%, #000428 50%, #001B33 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 20px #E8F2FF29;
    border-radius: 40px 100px 40px 40px;
    margin-top: -25px;
    /* position: absolute;
    left: 0;
    right: 0; */
    padding-top: 60px;
    display: flex;
    z-index: 1;
    padding-bottom: 20px;
    position: relative;

}
.section-one-wrapper .inner::before{
    background-image:url("../../../public/assets/images/about/quote-left.png");
    width: 200px;
    height: 200px;
    content: "";
    display: block;
    top: -90px;
    position: absolute;
    left: -35px;
    background-size: 200px 200px;

}

.section-one-wrapper .inner::after{
    background-image:url("../../../public/assets/images/about/quote-right.png");
    width: 200px;
    height: 200px;
    content: "";
    display: block;
    bottom: -45px;
    position: absolute;
    right: -54px;
    background-size: 200px 200px;
}

.section-one-wrapper .inner .left{
    overflow: hidden;
}
.section-one-wrapper .inner .pic{
    width: 225px;
    height: 225px;
    float: right;
}
.section-one-wrapper .inner .pic img{
    width: 100%;
    max-width: 225px;
}

.section-one-wrapper .inner .right{
    padding:0px 30px;
    color: #F3F5FB;
   
}
.section-one-wrapper .inner p{
    font-size: 18px;
    font-weight: normal;
    line-height: 28px;
    letter-spacing: 0px;
    /* overflow-y: scroll;
    height: 195px; */
}
.section-one-wrapper .inner .name{
    font-size: 36px;
    font-weight: 600;
    line-height: 44px;
    color: #F8A915;
    margin-top: 28px;
}
.section-one-wrapper .inner .subname{
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    margin-top:5px;
}


@media screen and (max-width: 576px) {
    .section-one-wrapper .inner{height: auto;flex-direction: column;row-gap: 10px;}
    .section-one-wrapper .inner .pic{
        text-align: center;
        width: 100%;
    }
    .section-one-wrapper .inner .right{text-align:center;}
}


.section-two{
    background: transparent linear-gradient(9deg, #000428 0%, #000428 35%, #133C6D 100%) 0% 0% no-repeat padding-box;
    border-radius: 161px 98px 0 0;
    position: relative;
    margin-top: -160px;
    padding-top: 200px;
    /* padding-bottom: 160px; */
    /* background: #133C6D; */
}
.section-two .slider{
    margin-top:60px
}
.section-two .box-slider {
    transition: all .3s ease;
    padding: 30px;
}
.section-two .box-slider .item {
    transition: all .3s ease;
    opacity: .8;
    height: 100%;
    min-height: 400px;
    position: relative;
    background: transparent linear-gradient(131deg, #000C4A 0%, #00020F 100%) 0% 0% no-repeat padding-box;
    border-radius: 40px 40px 40px 20px;
    text-align: center;
    padding-top: 70px;
    padding-bottom: 70px;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.section-two .center .slick-center .box-slider {
    opacity: 1;
    -ms-transform: scale(1.15);
    transform: scale(1.15);
}
.section-two .box-slider .item img{
    max-width: 80%;
    margin: 0 auto;
}
.section-two .box-slider .item p{
    margin-top:55px;
    color: var(--font-one);
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
}




.section-three{
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px;
}

.section-three .bg{
    background: url(../../../public/assets/images/background-about.png) no-repeat;
    display: flex;
    position: relative;
    min-height: 400px;
}
.section-three .item{
    position: relative;
}
.section-three .item p{
    position: absolute;
    top: 65%;
    text-transform: uppercase;
    width: 100%;
    justify-content: center;
    display: flex;
    color:var(--font-one);
    padding-right: 48px;
}
.section-three .item p img{
    padding-right: 5px;
}

.section-three .slick-prev {
    z-index: 9999;
    opacity: 1;
    /* background: #ffffff17; */
    height: 90%;
    width: 205px;
    left: 0;
    background: transparent linear-gradient(90deg, #001D38 0%, #001D3900 100%) 0% 0% no-repeat padding-box;
}
.section-three .slick-next {
    z-index: 9999;
    opacity: 1;
    /* background: #ffffff17; */
    height: 90%;
    width: 205px;
    right: 0;
    background: transparent linear-gradient(270deg, #00305C 0%, #001D3900 100%) 0% 0% no-repeat padding-box;
}

.section-three .slick-track .slick-slide.slick-active:first-child,.section-three .slick-slide.slick-active:last-child{
    opacity: 0.5;
}


.section-four{
    /* margin-top: -160px; */
    position: relative;
    background: #000428;
    padding-bottom: 260px;
}

.section-four .logos{
    width: 964px;
    margin: 0 auto;
    gap: 85px;
    border-bottom: 1px solid #f3f5fb9c;
    display: grid;
    grid-template-columns: auto auto auto auto;
    padding-left: 40px;
    padding-right: 40px;
    row-gap: 40px;
}

@media (max-width: 964px) {
    .section-four .logos { grid-template-columns: repeat(2, 1fr);width: auto; }
}

@media (max-width: 482px) {
    .section-four .logos { grid-template-columns: repeat(1, 1fr);width: auto; }
}

.section-four .logos .item{
    text-align: center;
    align-self: baseline;
}
.section-four .logos img{
    max-width: 100%;
}



.section-four .team{
    margin-top: 40px;
    display: flex;
    padding-left: 40px;
    padding-right: 40px;
    gap: 40px;
    row-gap: 40px;
    flex-wrap: wrap;
    justify-content: center;
}

.section-four .team .item{
    font-size: 22px;
    line-height: 28px;
    font-weight: 500;
    text-align: center;
}
.section-four .team .item img{
    
}
.section-four .team .item p.name{
    color: var(--primary);
}
.section-four .team .item p.designation{
    font-size: 16px;
    color: var(--font-one);
}



@media (max-width: 575px) {
    
    .section-one-wrapper .inner .left{
        padding-left: 0 !important;
    }

    .section-three .slick-prev{
        width: 70px;
    }
    .section-three .slick-next{
        width: 70px;
    }
}

@media (max-width: 1050px) {
    .section-one-wrapper .inner::before,.section-one-wrapper .inner::after{
        display: none;
    }
    .section-four .team{
        gap:10px;
        padding-left: 0;
        padding-right: 0;
    }
    .section-four .team .item{
        max-width: 47%;
    }
    .section-four .team .item img{
        max-width: 100%;
    }
}