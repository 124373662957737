.banner-home {
    /* background: var(--primary) !important; */
    padding-top: 112px;
    padding-bottom: 110px;
    margin-top: -72px;
    background: var(--primary) url("./banner-bg1.png");
    background-size: cover;
    min-height: 754px;
}

.banner-home .display-flex{
    display: flex !important;
}

.banner-home .left{
    margin-top: 40px;
}

.banner-home h1 {
    color: #000C4A;
    font-size: 72px;
    font-weight: 700;
    line-height: 72px;
}

.banner-home h1 span {
    color: var(--font-input);
}

.banner-home p {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    color: var(--font-color);
}

.banner-home .home-p {
    color: #000 !important;
    font-size: 22px;
    line-height: 30px;
    font-weight: 500;
}


.left {
    padding-left: 100px !important;
}

.banner-home .button-cta {
    min-width: 130px;
    padding: 0px 20px;
    border-color: transparent;
    background: transparent linear-gradient(319deg, #133D6E 0%, #010723 100%) 0% 0% no-repeat padding-box;
    color: var(--font-one);
    min-height: 45px;
    margin-top: 20px;
    border-radius: 8px;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 90px;
    font-weight: 600;
    transform: translate(0px);
    cursor: pointer;
}

.banner-home .button-cta:hover {
    color: var(--primary);
    /* font-weight: 600;
    transform: translate(20px);
    transition-duration: 1000ms; */
}



.banner-home img{
    max-width:100%;
 }


.banner-home .slick-prev:before, .banner-home .slick-next:before{
    background: #fff !important;
    color: var(--primary);
    opacity: 1;
}



/*for animate banner*/
.banner-home .kwikchat {
    width: 100%;
    margin: 0 auto;
}

.banner-home .kwikchat .imgWrapper {
    text-align: center;
    position: relative;
    width: 250px;
    margin: 0 auto;
}

.banner-home .kwikchat .imgWrapper .screen {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
}

.banner-home .text {
    background-image: linear-gradient(var(--font-input), var(--font-input));
    width: 100px;
    padding: 6px;
    text-align: center;
    font-size: 12px;
    border-radius: 18px;
    color: var(--primary);
}

.banner-home .arrow-border {
    height: 1px;
    background: #fff;
    position: absolute;
    width: 150px;
    margin-left: 102px;
    top: 50%;
}

.banner-home .box-screen {
    position: absolute;
    opacity: 0;
}

.banner-home .box-screen.right {right: 0;}

.banner-home .placeholder {
    position: relative;
    opacity: 0;
}

.banner-home .box-screen.right .arrow-border {
    margin-left: -147px;
}

.banner-home .box-screen {
    position: absolute;
    top: 33%;
}

.banner-home .thankyouWrapper {
    width:100%;
}

.banner-home canvas.confetti {
    position: absolute;
    margin-left: 230px;
    margin-top: 150px;
}

.banner-home svg{
    max-width: 100% !important;
}

@media (max-width: 1024px) {

    .banner-home{
        min-height:auto !important;
        padding-bottom:100px !important;
    }
    .banner-home .left{
        padding: 0 20px !important;
        margin-top: 0;
        margin-bottom: 20px;
    }

    .banner-home svg{
        /* transform: scale(0.5);
        margin-left: -47%; */
        max-width: 100% !important;
    }
}


@media (max-width:576px) {

    .banner-home h1{
        font-size: 42px !important;
        line-height: 48px !important;
    }
    .banner-home p{
        font-size: 18px !important;
        line-height: 24px !important;
    }
    /* Only for checkout app */
    .banner-home .smart-codh1{
        font-size: 33px !important;
        line-height: 48px !important;
    }
    .kwickcheckoutappbanner {
        margin-top: -39px;
    }
    .slick-slider.slick-initialized {
        margin-top: -32px;
    }
    /*End Only for checkout app */
}










.st0{fill:none;}
.st1{fill:#FFFFFF;}
.st2{fill:#F79C1D;}
.st3{fill:url(#SVGID_1_);}
.st4{fill:url(#SVGID_00000062184380219007254610000010371543704604076466_);}
.st5{fill:url(#SVGID_00000045607973786601174740000003672524009075039379_);}
.st6{fill:url(#SVGID_00000132062955684571561150000016876993779323233705_);}
.st7{fill:url(#SVGID_00000118360531016852779670000014539209704346316945_);}
.st8{fill:url(#SVGID_00000162353561641385482920000010761052127881492637_);}
.st9{fill:url(#SVGID_00000049184783363188484520000005817597615076537269_);}
.st10{fill:#FCE9CB;}
.st11{fill:url(#SVGID_00000181778702962740844660000015404404013145858706_);}
.st12{fill:url(#SVGID_00000093876093837099922200000002223368252990428078_);}
.st13{fill:url(#SVGID_00000170251862925508920850000002775864463566540696_);}
.st14{fill:url(#SVGID_00000026861298361289303000000015736177586387392141_);}
.st15{fill:url(#SVGID_00000023262484709498236930000005002181261870645167_);}
.st16{fill:url(#SVGID_00000157307039808731106040000011008749524374138778_);}
.st17{fill:url(#SVGID_00000100357453986464798890000000389752514416223380_);}
.st18{fill:url(#SVGID_00000078733072690641197720000016216925105192774536_);}
.st19{fill:#EAEAEA;}
.st20{fill:#9A9797;}
.st21{opacity:0.15;fill:#010101;enable-background:new    ;}
.st22{fill:#E9F1FA;}
.st23{fill:#F9A11B;}
.st24{opacity:0.33;fill:#010101;enable-background:new    ;}
.st25{fill:#F1B359;}
.st26{opacity:0.11;fill:#010101;enable-background:new    ;}
.st27{fill:#F7C778;}
.st28{fill:#FCD593;}
.st29{fill:url(#SVGID_00000080165758446704749860000007616574741922544535_);}
.st30{fill:url(#SVGID_00000152960697327190498440000013701206645622843029_);}
.st31{fill:url(#SVGID_00000105388475825632490450000013572728358420142520_);}
.st32{fill:url(#SVGID_00000113315534869154508870000011575651511818234278_);}
.st33{fill:url(#SVGID_00000001638182536534409270000005253323248866615710_);}
.st34{fill:url(#SVGID_00000013902325376848865350000011584936438168431525_);}
.st35{fill:#EAAE46;}
.st36{fill:#B3742A;}
.st37{fill:#E0E1E0;}
.st38{fill:#1A4D88;}
.st39{opacity:0.15;fill:#1A4D88;enable-background:new    ;}
.st40{fill:url(#SVGID_00000018231086535566632990000012973600015561349271_);}
.st41{opacity:0.5;fill:#FFFFFF;enable-background:new    ;}
.st42{fill:url(#SVGID_00000180336440655014008790000010912962526671880102_);}
.st43{fill:url(#SVGID_00000080190986647125445790000000779491531005581987_);}
.st44{fill:url(#SVGID_00000116193920268699378160000015611911973958856334_);}
.st45{opacity:0.95;fill:#FFFFFF;enable-background:new    ;}
.st46{fill:#09B256;}
.st47{opacity:0.96;fill:#FFFFFF;enable-background:new    ;}
.st48{fill:none;stroke:#F9A11B;stroke-width:0;stroke-linecap:round;stroke-linejoin:round;}
.st49{fill:url(#SVGID_00000115496679703155050070000001927136233918475695_);}







.banner-main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0rem;
    margin-top: -210px;
}
