.banner-one{
  
}


.banner-one .left{
   margin-top: 23%;
}

@media (max-width: 767px) {
   .banner-one .left{
       margin-top: 0;
       margin-bottom: 20px;
   }
}

