.rightside {
    padding-right: 0;
    position: fixed;
    width: 37%;
    right: 0;
}

.leftside {
    float: left;
    width: 63%
}

.one-click-checkout {
    background: linear-gradient(180deg, #0c3c67, #00213d 31.03%, #000e1a);
    margin-top: 0;
    padding-top: 30px;
}

.one-click-checkout .top-section {
    margin-bottom: 20px;
    padding: 0 40px;
}

.growth-contact-section .form {
    background: #0000 linear-gradient(209deg, #f89b15, #f3ad47 73%, #f6e3ca 100%, #f6e6d0 0) 0 0 no-repeat padding-box;
    border-radius: 58px 0 0 0;
    min-height: 700px;
    padding-bottom: 250px;
    width: 100%;
    height: 100vh;
}

.growth-contact-section .form .form-wrapper {
    margin: 0 auto;
    max-width: 464px;
    padding: 70px 30px 100px;
}

.one-click-checkout .b-heading {
    font-size: 50px;
    line-height: 60px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 20px;
}

.one-click-checkout .home-p {
    color: #fff;
    font-size: 24px;
    font-weight: 300;
    line-height: 30px;
}

.check-banner-info p {
    color: #fff;
    font-weight: 300;
    margin-left: 35px;
    font-size: 18px;
    margin-right: 5px;
}

.onekwickcheckc {
    margin-top: 40px;
}

.check-banner-info {
    background-color: #0C3B65;
    padding: 30px;
    display: flex;
    align-items: center;
    margin-top: 70px;
    margin-left: -115px;
    padding-left: 65px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.growth-contact-section .btn-submit {
    background: linear-gradient(180deg, #0C3C67, #00213D) 0 0 no-repeat padding-box;
    border: 0;
    border-radius: 8px;
    box-shadow: none;
    color: #fff;
    display: block;
    font-size: 16px;
    font-weight: 600;
    height: 48px;
    line-height: 20px;
    margin: 30px auto 0;
    text-align: center;
    transition: all .5s ease-out;
    width: 220px;
}

.growth-contact-section .form-title {
    text-align: center;
    margin-bottom: 30px;
}

.growth-contact-section .form-title h2 {
    font-size: 40px;
    font-weight: 600;
}

.growth-contact-section .form-title p {
    font-size: 24px;
    font-weight: 300;
}

.table-checkout {
    background: linear-gradient(140deg, #E8F2FF, #7EB4FF);
    margin-right: -20px;
    margin-left: -20px;
    padding: 30px 0 50px 5px;
}

.table-checkout h1 {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 30px;
    margin-top: 30px;
    color: #000;
    padding: 0 20px;
    text-align: center;
}

.table-checkout table th,
td {
    border: 1px solid #000;
    padding-bottom: 10px;
    padding-left: 8px;
    padding-top: 10px;
    color: #000;
}

.table-checkout table th {
    font-size: 16px;
    
}
.table-checkout .sticky-header{
    position: sticky;
    top: 0; 
    background-color: #D8E9FF;
    z-index: 1;
}
.table-checkout .sticky-subheader{
    position: sticky;
    top: 90px; 
    background: linear-gradient(140deg,#e8f2ff,#7eb4ff);
    z-index: 1;
}

.tblwidth {
    width: 120px;
    text-align: center;
    background: linear-gradient(180deg, #0C3C67, #00213d);
    color: #fff !important;
}

.tblbgcolor {
    width: 165px;
    background: #0000 linear-gradient(181deg, #f89b15, #f3ad47 75%, #f6e3ca 184%) 0 0 no-repeat padding-box;
    text-align: center;
}

.tblalign {
    text-align: center;
}

.titlepad {
    padding: 10px 20px 10px 20px;
}

.one-kcheckout-client {
    background: linear-gradient(180deg, #0c3c67, #00213d 31.03%, #000e1a);
    margin-right: -20px;
    margin-left: -20px;
}

.one-kcheckout-client .client_box {
    padding: 45px 0 150px;
}

.one-kcheckout-client .grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    overflow: hidden;
    align-items: center;
    padding: 0 30px;
}

.one-kcheckout-client .grid img {
    width: 115px;
}

.one-kcheckout-client .heading h2 {
    color: #fff;
    font-size: 28px;
    text-align: center;
    font-weight: 600;
    margin-bottom: 30px;
    padding: 0 30px;
}

.oneclick-shoppers-section {
    background: #F89B15;
    border-radius: 40px;
    margin: -100px 60px 0 60px;
    position: relative;
    z-index: 1;
    padding-bottom: 40px;
    padding-top: 30px;
}


.oneclick-shoppers-section .content {
    text-align: center;
}

.oneclick-shoppers-section .content h4 {
    font-size: 70px;
    color: #fff;
    font-weight: 800;
}

.oneclick-shoppers-section .item {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 0 10px;
}

.peoplesay-section-five {
    background: linear-gradient(180deg, #0c3c67, #00213d 31.03%, #000e1a);
    margin: -120px -20px 0 -20px;
    border-top-left-radius: 70px;
    border-top-right-radius: 70px;
}

.peoplesay-section-five .items .item {
    background: #000428;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin: 0 auto;
    margin-bottom: 30px;
    padding: 30px 15px;
    row-gap: 30px;
    height: 270px;
}

.peoplesay-section-five .items .item .img-info {
    display: flex;
}

.peoplesay-section-five .peopleheader {
    padding-top: 168px;
    margin-bottom: 40px;
    padding-bottom: 0;

}

.peoplesay-section-five .peopleheader h3 {
    color: #fff;
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 40px;
    text-align: center;
    text-transform: none;
    padding: 0px 100px;
}

.peoplesay-section-five .peopleheader p {
    text-align: center;
    font-size: 20px;
    font-weight: 100;
}

.peoplesay-section-five .img-info .icon {
    width: 115px;
    right: 0;
    position: absolute;
}

.peoplesay-section-five .check-name {
    display: flex;
    font-size: 12px;
    font-weight: 400;
    margin-top: 12px;
    margin-left: 10px;
    color: #f3ad47;
}

.detail-desp h4 {
    font-size: 18px;
    font-weight: 600;
}

.detail-desp p {
    font-size: 13px;
}

.oneclick-shoppers-inner .section-heading {
    color: #000;
}

@media screen and (max-width: 320px) {
    .leftside {
        float: left;
        width: 100%;
    }

    .rightside {
        /* padding-right: 0; */
        position: relative;
        width: 100%;
        right: 0;
    }

    .table-checkout {
        background: linear-gradient(140deg, #E8F2FF, #7EB4FF);
        margin-right: -5px;
        margin-left: -5px;
        padding: 30px 10px 50px 10px;
    }

    .left {
        padding-left: 0px !important;
    }

    .growth-contact-section .form .form-wrapper {
        margin: 0 auto;
        max-width: 464px;
        padding: 35px 30px 0;
    }

    .growth-contact-section .form {
        background: #000e1b;
        border-radius: 0;
        min-height: 700px;
        padding-bottom: 250px;
        width: 100%;
        margin-left: -5px;
    }

    .growth-contact-section .form-title {
        display: none;
    }

    .peoplesay-section-five .kwik-adv a {
        display: none;
    }

    .kwik-content {
        padding: 0 30px 30px;
    }

    .kwik-content p {
        color: #FFF;
        font-size: 28px;
    }

    .peoplesay-section-five .img-info .icon {
        width: 115px;
        right: 0;
        top: 0;
        position: absolute;
    }

    .onekwickcheckc .check-banner-info {
        margin-left: -45px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .one-click-checkout .b-heading {
        text-align: center;
        font-size: 30px;
        line-height: 45px;
    }

    .one-click-checkout .home-p {
        text-align: center;
        font-size: 18px;
    }

    .flex-flow {
        flex-flow: column-reverse;
    }

    .mobileview {
        text-align: center;
    }

    .mobileview img {
        width: 100%;
    }

    .mobileview .button-cta {
        background: #F89B15;
        border-color: #0000;
        border-radius: 8px;
        color: #000;
        cursor: pointer;
        font-size: 20px;
        font-weight: 600;
        line-height: 22px;
        margin-bottom: 50px;
        min-height: 55px;
        padding: 0 20px;
        transform: translate(0);
    }

    .table-checkout h1 {
        text-align: center;
    }

    .oneclick-shoppers-inner {
        background: #F89B15;
        margin-right: -20px;
        margin-left: -20px;
        padding-bottom: 150px;

    }

    .oneclick-shoppers-section .content h4 {
        font-size: 40px;
    }

    .oneclick-shoppers-section .content p {
        font-size: 8px;
    }

    .oneclick-shoppers-section {
        background: #F89B15;
        border-radius: 40px;
        margin: -100px 20px 0 20px;
        position: relative;
        z-index: 1;
        padding-bottom: 40px;
        padding-top: 30px;
    }

    .kwik-adv {
        text-align: center;
        margin-top: 70px;
        margin-bottom: 0;
    }

    .kwik-content {
        margin-bottom: 0;
    }
    .table-checkout table td {
        height: 140px;
        
    }
    
}

@media only screen and (min-width:280px) and (max-width:768px) {
    .leftside {
        float: left;
        width: 100%;
    }

    .rightside {
        /* padding-right: 0; */
        position: relative;
        width: 100%;
        right: 0;
    }

    .table-checkout {
        background: linear-gradient(140deg, #E8F2FF, #7EB4FF);
        margin-right: -5px;
        margin-left: -5px;
        padding: 30px 10px 50px 10px;
    }

    .left {
        padding-left: 0px !important;
    }

    .growth-contact-section .form .form-wrapper {
        margin: 0 auto;
        max-width: 464px;
        padding: 35px 30px 0;
    }

    .growth-contact-section .form {
        background: #0000 linear-gradient(209deg, #f89b15, #f3ad47 73%, #f6e3ca 100%, #f6e6d0 0) 0 0 no-repeat padding-box;
        border-radius: 0;
        min-height: 700px;
        padding-bottom: 250px;
        width: 100%;
        margin-left: -5px;
    }

    .growth-contact-section .form-title {
        display: none;
    }

    .peoplesay-section-five .kwik-adv a {
        display: none;
    }

    .kwik-content {
        padding: 0 30px 30px;
    }

    .onekwickcheckc .check-banner-info {
        margin-left: -45px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .one-click-checkout .b-heading {
        font-size: 30px;
        line-height: 45px;
        text-align: center;
    }

    .one-click-checkout .home-p {
        font-size: 22px;
        text-align: center;
    }

    .flex-flow {
        flex-flow: column-reverse;
    }

    .mobileview {
        text-align: center;
    }

    .mobileview img {
        width: 100%;
    }

    .mobileview .button-cta {
        background: #F89B15;
        border-color: #0000;
        border-radius: 8px;
        color: #000;
        cursor: pointer;
        font-size: 20px;
        font-weight: 600;
        line-height: 22px;
        margin-bottom: 50px;
        min-height: 55px;
        padding: 0 20px;
        transform: translate(0);
    }

    .table-checkout h1 {
        text-align: center;
    }

    .oneclick-shoppers-inner {
        text-align: center;
    }

    .oneclick-shoppers-inner {
        background: #F89B15;
        margin-right: -20px;
        margin-left: -20px;
        padding-bottom: 110px;

    }

    .oneclick-shoppers-inner .button-cta {
        background: #0000 linear-gradient(180deg, #0c3c67, #00213d) 0 0 no-repeat padding-box;
        border-color: #0000;
        border-radius: 5px;
        color: #fff;
        font-size: 20px;
        font-weight: 600;
        height: 50px;
        padding: 8px 15px;
        transform: translate(0);
    }

    .oneclick-shoppers-section .content h4 {
        font-size: 40px;
    }

    .oneclick-shoppers-section .content p {
        font-size: 8px;
    }

    .oneclick-shoppers-section {
        background: #F89B15;
        border-radius: 40px;
        margin: -100px 20px 0 20px;
        position: relative;
        z-index: 1;
        padding-bottom: 40px;
        padding-top: 30px;
    }

    .kwik-adv {
        text-align: center;
        margin-top: 70px;
        margin-bottom: 0;
    }

    .kwik-content {
        margin-bottom: 0;
    }
    .onekwickcheckc .home-p{
        display: none
    }
    .scroll-view-mobile .scroll-table{
        overflow-x: auto;
        margin-bottom: 30px;
    }
    .scroll-view-deskstop{
        display: none;
    }
    .table-checkout table td {
        height: 140px;
        
    }
}
@media only screen 
and (min-device-width : 769px) 
and (max-device-width : 1024px) {
    .onekwickcheckc .home-p-mobile{
        display: none;
    }
    .scroll-view-mobile{
        display: none;
    }
}
@media only screen and (max-width:280px) {
    .peoplesay-section-five .img-info .icon {
        width: 115px;
        right: 0;
        top: 0;
        position: absolute;
    }

    .onekwickcheckc .check-banner-info {
        margin-left: -45px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .one-click-checkout .b-heading {
        font-size: 30px;
        line-height: 45px;
        text-align: center;
    }

    .one-click-checkout .home-p {
        font-size: 18px;
        text-align: center;
    }

    .mobileview {
        text-align: center;
    }

    .table-checkout h1 {
        text-align: center;
    }

    .oneclick-shoppers-section .content h4 {
        font-size: 40px;
    }

    .oneclick-shoppers-section .content p {
        font-size: 8px;
    }

    .oneclick-shoppers-section {
        background: #F89B15;
        border-radius: 40px;
        margin: -100px 20px 0 20px;
        position: relative;
        z-index: 1;
        padding-bottom: 40px;
        padding-top: 30px;
    }
    .onekwickcheckc .home-p{
        display: none
    }
    .scroll-view-mobile .scroll-table{
        overflow-x: auto;
        margin-bottom: 30px;
    }
    .scroll-view-deskstop{
        display: none;
    }
    .table-checkout table td {
        height: 140px;
        
    }
}

@media only screen and (min-width: 1024px) {
    .mobileview {
        display: none;
    }

    .oneclick-shoppers-inner {
        display: none;
    }
    .onekwickcheckc .home-p-mobile{
        display: none;
    }
    .scroll-view-mobile{
        display: none;
    }
}

@media only screen and (min-width: 1280px) {
    .mobileview {
        display: none;
    }

    .oneclick-shoppers-inner {
        display: none;
    }
    .onekwickcheckc .home-p-mobile{
        display: none;
    }
    .scroll-view-mobile{
        display: none;
    }
}

@media only screen and (max-width: 1280px) {
    .titlepad {
        padding: 5px 8px 5px 8px;
        font-size: 13px;
    }
}
.back-end-content{
    text-align: center;
    display: none;
    
}
.back-end-content p{
    margin-bottom: 15px !important;
}

.onekwickcheckc .home-p-mobile {
    text-align: center;
    font-size: 18px;
    padding: 0 10px;
}
.table-checkout img{
    width: 34px;
}
