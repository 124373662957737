.experts .card {
    background: transparent linear-gradient(224deg, #00315D 0%, #001B33 100%) 0% 0% no-repeat padding-box !important;
    border-radius: 20px !important;
    padding: 20px !important;
    margin: 5px;
    height: 315px;
    max-width: 324px;
    transform: translateY(0px);
    transition: 0.5s;
    margin: 0 auto;
    position: relative;
}

.experts .card .play-icon{
    position: absolute;
    width:20%;
    max-width: 64px;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 30%;
    opacity: 0.75;
    transition: all 500ms;
    z-index:9;
    cursor: pointer;
}
.experts .card .play-icon img{
    border: 0;
    border-radius: 0;
    max-width: 100%;
}
.experts .card:hover .play-icon{
    opacity: 1;
}

.experts .card-img{
    padding-bottom: 15px;
    cursor: pointer;
}

.experts .card:hover {
    transform: translateY(-20px);
}


.experts .col-md-12 {
    padding-top: 25px;
}
.experts .card-img{
    padding-bottom: 15px;
}
.experts .card-img img{
    border-radius: 20px;
    width: 100%;
}
.experts .flex-between{
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
    margin-top: 15px;
}
.experts .slider-p{
    margin-bottom: 0 !important;
}
.experts .profile-pic {
    width: 54px;
    height: 54px;
}
.experts .profile-pic img{
    width: 100%;
    border-radius: 50%;
}
.experts  .jhon {
    display: flex;
    flex-direction: column;
    align-items: baseline;
}
.experts  .jhon h5 {
    font-size: 18px;
    line-height: 22px;
    color: var(--primary);
    margin-bottom: 0%;
    font-weight: 500;
}


.experts  .jhon p {
    font-size: 14px !important;
    line-height: 22px !important;
    color: var(--font-one);
    text-align: left;
}

.experts  .w-aut i {
    background: var(--primary);
    padding: 10px;
    font-size: 20px;
    border-radius: 10px;
}




@media (max-width: 900px) {
    .experts .box{
        right:-10px
    }
}
