.event-section-one{
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 79px 79px 0 0;
    position: relative;
    margin-top: -120px;
    padding-top: 80px;
    padding-bottom: 200px;
}

.kwik-mixer .tab-one{
    max-width: 1172px;
}

.kwik-mixer .form-item {
  max-width: 100%;
}

.event-section-one .items{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    gap: 60px;
    row-gap: 60px;
    margin-top: 40px;
    margin-bottom: 70px;
}
.event-section-one .items .item{
    text-align: left;
    align-self: baseline;
    max-width: 476px;
    height:auto;
    /* cursor:pointer; */
}
.event-section-one .items .item img{
    width: 100%;
    border-radius: 15px;
    border-radius: 80px 20px 20px 20px;
    /* border: 2px solid #FFFFFF; */
}
.event-section-one .items .item:hover img{
    /* cursor:pointer; */
}
.event-section-one .items .item p.category{
    text-align: left;
    font-size: 12px;
    font-weight: 300;
    line-height: 22px;
    color: #F9D6A3;
    margin-top: 10px;
}
.event-section-one .items .item p.title{
    text-align: left;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0px;
    color: var(--primary);
    margin-top: 5px;
    margin-bottom: 5px !important;
}
.event-section-one .items .item  a{
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    color: #fff;
    padding-left: 0px;
}
.event-section-one .items .item  a:hover{
    color: var(--primary);
}
@media (max-width: 769px) {
    .event-section-one .items{
        row-gap: 30px;
        margin-bottom: 30px;
    }
}








.modal-content{
    background: #000;
    border: 0;
    border-radius: 25px;
}
.modal-header{
    height: 0;
    padding: 0;
    margin: 0;
    border: 0;
}
.modal-header .btn-close{
    opacity: 1;
    background-color: #fff;
    border-radius: 50px;
    z-index: 9;
}

.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus,.image-gallery-thumbnail:hover{
    border: 4px solid var(--primary);
}
.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg{
    width: 40px;
}
.image-gallery-icon:hover{
    color: var(--primary);
}
.image-gallery-slide .image-gallery-description{
    color: var(--primary);
}


/* Kwik Mixer */
.kwik-mixer .brands-section-three {
  background: transparent linear-gradient(201deg, #133C6D 0%, #000428 57%, #001B33 100%) 0% 0% no-repeat padding-box;
  border-radius: 40px 100px 40px 40px;
  margin-top: -95px;
  position: relative;
  padding-bottom: 160px;
}
.kwik-mixer .detail-page-form button:hover{
color: #fff;
}
.kwik-mixer .detail-page-form button:focus{
  outline: 0;
  box-shadow: none;
}
.paradescp{
    max-width: 630px;
    margin: 0 auto!important;
}

.kwik-img img{
    max-width: 100%;
}
.km-item-caption{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 2em;
    color: #fff;
    text-transform: uppercase;
    font-size: 1.25em;
}
.kwik-gallery {
    position: relative;
    margin: 0 auto;
    max-width: 1000px;
    text-align: center;
  }
  
  .kwik-gallery .gallery-item {
    position: relative;
    float: left;
    margin: 0;
    width: 100%;
    cursor: pointer;
  }
  
  .kwik-gallery .gallery-item-active img {
    position: relative;
    display: block;
    opacity: .55;
    width: 100%;
    height: auto;
    backface-visibility: hidden;
    margin-bottom: 20px;
    background-color: aliceblue;
    border-radius: 40px;
  }
  .gallery-item-upcoming img{
    position: relative;
    display: block;
    opacity: .30;
    width: 100%;
    height: auto;
    backface-visibility: hidden;
    margin-bottom: 20px;
    background-color: aliceblue;
    border-radius: 40px;
  }
 
  .kwik-gallery .gallery-item .gallery-item-caption-grey {
    padding: 2em;
    color: #ffffff8c;
    font-size: 1.25em;
  }
  .kwik-gallery .gallery-item .gallery-item-caption-grey,
  .kwik-gallery .gallery-item .gallery-item-caption-grey > a {
    position: absolute;
    top: 65px;
    left: -10px;
    width: 100%;
    height: 100%;
  }
  .kwik-gallery .gallery-item .gallery-item-caption {
    padding: 2em;
    color: #fff;
    font-size: 1.25em;
  }
  
  .kwik-gallery .gallery-item .gallery-item-caption,
  .kwik-gallery .gallery-item .gallery-item-caption > a {
    position: absolute;
    top: 65px;
    left: -10px;
    width: 100%;
    height: 100%;
  }
  
  .kwik-gallery .gallery-item h2 {
    font-weight: 400;
    overflow: hidden;
    padding-bottom: 0;
    font-size: 50px;
  }
  
  
  .kwik-gallery .gallery-item h2,
  .kwik-gallery .gallery-item p {
    position: relative;
    margin: 0;
    z-index: 10;
  }
 
  .kwik-gallery .gallery-item:hover img {
    opacity: .3;
    transform: translate3d(0, 0, 0);
  
  }


  .kwik-gallery .gallery-item .gallery-item-caption-grey {
    text-align: center;
  }
  .kwik-gallery .gallery-item .gallery-item-caption {
    text-align: center;
  }
  
 
  .kwik-gallery .gallery-item:hover p {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  .primary-button {
    background: transparent linear-gradient(180deg,#0c3c67,#00213d) 0 0 no-repeat padding-box;
    box-shadow: none;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
  }
  
  @media only screen and (min-width: 992px) {
    .kwik-gallery .gallery-item {
      display: inline-block;
      float: none;
      width: 100%;
    }
  }
  