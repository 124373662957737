
.bg-light{
    background: transparent linear-gradient(180deg, #133C6D 0%, #000428 64%, #000428 100%) 0% 0% no-repeat padding-box;
}
.bg-dark{
    background: transparent linear-gradient(199deg, #133C6D 0%, #000428 29%, #000428 100%) 0% 0% no-repeat padding-box;
}

.apidoc-section-one{
    position: relative;
    background: transparent linear-gradient(17deg, #000428 0%, #000428 54%, #133C6D 100%) 0% 0% no-repeat padding-box;
}

.apidoc-section-one .section{
    position: relative;
    top: -70px;
    width: 90%;
    margin: 0 auto;
    border-radius: 86px 106px 40px 40px;
    padding-bottom: 150px;
    max-width: 100%;
}

.apidoc-section-one .section.secound{
    background: transparent linear-gradient(10deg, #001B34 0%, #000428 58%, #002F59 100%) 0% 0% no-repeat padding-box;
    box-shadow: 3px 3px 6px #00000029;
    border-radius: 79px 79px 0px 0px;
    width: auto;
    margin-top: -70px;
    top: unset;
}

.apidoc-section-one .section .sub-section-header{
    margin-bottom: 0px;
}

.apidoc-section-one .section .items{
    display: flex;
    gap: 80px;
    row-gap: 40px;
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: center;
    text-align: left;
    max-width: 100%;
    margin-top: 50px;
    padding-left: 10px;
    padding-right: 10px;
}


.apidoc-section-one .section .items .item{
    max-width: 100%;
    /* min-width: 170px; */
}
/* .apidoc-section-one .section.secound .items{
    gap: 120px;
} */


.apidoc-section-one .section .items .item p{
    font-size:24px;
    line-height: 24px;
    font-weight: 600;
}
.apidoc-section-one .section .items .item p span{
    color: var(--primary);
}
.apidoc-section-one .section .items .item .img-wrapper{
    height: 100px;
    display: flex;
    min-width:150px;
}
.apidoc-section-one .section .items .item .img-wrapper img{
    align-self: center;
    width: auto;
}

.apidoc-section-one .section .items .item a{
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    color: #fff;
    padding-left: 0px;
    margin-top: 5px;
    display: block;
}
.apidoc-section-one .section .items .item a:hover{
    color: var(--primary);
}



.apidoc-sucess-story-top{
    top: -180px;
}
.apidoc-network-effect-top{
    margin-top: -135px;
}

@media (max-width: 900px) {
    .apidoc-section-one .section .items{
        text-align: center;
    }

    .apidoc-section-one .section.secound .items{
        max-width: 600px;
    }
   
    .apidoc-section-one .section .items .item .img-wrapper{
        justify-content: center;
       
    }
}

@media (max-width: 480px) {
    .apidoc-section-one .section .items{
        gap:10px;
    }
    .apidoc-section-one .section .items .item .img-wrapper{
        min-width: 130px;
        height: 80px;
    }
    .apidoc-section-one .section .items .item {
       
        margin-bottom: 50px;
    }
}

@media (max-width: 330px) {
    .apidoc-section-one .section .items{
        gap:0px;
    }
}
