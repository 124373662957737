.kwik-checkout-cod{
    background: var(--primary) url(/public/assets/images/kwik-checkout-cod/bannerbg.svg);
    margin-top: -72px;
    padding-top: 100px;
    padding-bottom: 150px;
    background-position: center;
    background-size: cover;
}

.kwik-checkout-cod .b-heading{
        color: #000;
        font-size: 44px;
        font-weight: 800;
        line-height: 54px;
   
}
.kwik-checkout-cod img{
  max-width: 100%;
}
.kwik-checkout-cod .top-section {
  margin-bottom: 50px;
  padding: 0 50px;
}

.kwik-checkout-cod .top-section .toplogo {
  float: left;
}

.kwik-checkout-cod .checkcod {
  padding: 0 50px;
}

.kwik-checkout-cod .checkcod .t-head {
  font-size: 20px;
  color: #000;
}

.kwik-checkout-cod .checkcod p {
  font-size: 18px;
}
.kwik-checkout-cod .checkcod h2 {
  font-size: 24px;
  color: #F68D13;
}
.kwik-checkout-cod .home-p ul{
  font-size: 20px;
}
.kwik-checkout-cod .b-heading {
  color: #000;
  font-size: 45px;
  font-weight: 700;
  line-height: 50px;

}

.kwik-checkout-cod .home-p {
  color: #000;
  font-size: 24px;
  font-weight: 300;
  line-height: 30px;
  margin-top: 20px;
  font-weight: 400;
}

.cod-banner-info {
  /* background-color: #F39A32; */
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
  margin-left: -70px;
  padding-left: 65px;

}

.cod-banner-team {
  background-color: #fff;
  color: #000;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  margin-left: -80px;
  margin-top: 30px;
  margin-bottom: 20px;
  padding: 10px 30px 45px 80px;
  width: auto !important;
}

.cod-banner-team .cod-bimg img {
  margin-left: 45px;
}

.exclusive-offer-container {

  background-image: linear-gradient( #E8F2FF, #7EB4FF);
  padding-inline: 40px;
  padding-bottom: 20px;
  max-width: 1165px;
  margin-inline: auto;
  margin-top: 15px;
  border-radius: 10px;
}

.exclusive-offer-onheader {
  text-align: center;
  background-color: #F4AF4A;
  max-width: fit-content;
  padding: 5px 15px;
  color: #000;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.exclusive-offer-paragraph {
  /* max-width: 400px; */
  font-size: 30px;
  margin-top: 30px;
  font-weight: 500;
  color: #03306E;
}

.offer-button {

  background-image: linear-gradient(to bottom, #0C3C67 0%, #00213D 100%);
  padding: 8px 25px;
  border-radius: 6px;
  font-size: 18px;
  margin-bottom: 10px;
  border: none;
  color: #fff;
  width: 250px;
  text-align: center;
  /* margin-left: 15px; */
}

.exclusive-offer-button-container {
  margin-top: 70px;
}

/* section two */

.checkooutcod-section-two {
  background: linear-gradient(0deg, #000e1a 0%, #00213d 64%, #0c3c67 100%);
  border-top-left-radius: 80px;
  border-top-right-radius: 80px;
  padding-bottom: 100px;
  overflow: hidden;
  position: relative;
  margin-top: -100px;
}

.checkooutcod-section-two .sec-width {
  background: linear-gradient(0deg, rgba(4, 42, 74, 1) 0%, rgba(0, 14, 26, 1) 100%);
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  margin: 0 auto;
  max-width: 100%;
  min-height: 47%;
  padding: 40px 60px;
  width: 1200px;
}
.checkooutcod-section-two .sec-width .right-side img{
  padding: 0;
}
.checkooutcod-section-two .sec-width .left-side h3 {
  font-size: 36px;
  line-height: 42px;
  font-weight: 600;
  padding-top: 25px;
  margin-top: 0;
}

.checkooutcod-section-two .inner {
  text-align: center;
  margin-bottom: 50px;
}

.checkooutcod-section-two .inner h2 {
  color: #F79D1C;
  font-size: 40px;
}

.checkooutcod-section-two .inner p {
  font-size: 28px;
}

.checkooutcod-section-two .descr li {
  font-size: 20px;
}

.smart-cod-offer {
  background: linear-gradient(0deg, #7EB4FF 0%, #E8F2FF 100%);
}

.smart-cod-offer .offer-img {
  max-width: 1040px;
  margin-inline: auto;
  text-align: center;
}

.smart-cod-offer .offer-img img {
  margin-top: -31px;
}

.smart-cod-container {
  padding: 30px 0;
  max-width: 1100px;
  margin-inline: auto;
  border-radius: 10px;
  text-align: center;
}
.cod-botom{
  padding-bottom: 100px;
  margin-bottom: -50px;
}
.smart-cod-onheader {
  text-align: center;
  background-color: #F39A32;
  border-radius: 4px;
  max-width: fit-content;
  padding: 5px 15px;
}

.smart-cod-paragraph {
  font-size: 40px;
  font-weight: 600;
}

.smart-cod-text {
  color: #03306E;
}

.smart-cod-text p {
  font-size: 24px;
}

.smart-cod-button-container {
  text-align: center;
}

.smart-cod-offer-button {
  background-color: #0C3C67;
  background-image: linear-gradient(to bottom, #00213D 0%, #0C3C67 100%);
  padding: 8px 25px;
  border-radius: 6px;
  font-size: 16px;
  translate: 0 15px;
  border: none;
  color: #fff;
  width: 250px;
  margin-bottom: 10px;
}



/* section three start*/

.turbocharge-form-section {
  background: #F79F23;
  padding-top: 60px;

}

.turbocharge-form-section .form {

  padding-bottom: 120px;
  margin-bottom: -50px;
}

.turbocharge-form-section .form .form-wrapper {
  margin: 0 auto;
  max-width: 464px;

}

.turbocharge-form-section .form-title {
  text-align: center;
  margin-bottom: 30px;
}

.turbocharge-form-section .form-title h2 {
  color: #011220;
  font-size: 40px;
  font-weight: 600;
}

.turbocharge-form-section .form-title p {
  font-size: 28px;
}

.turbocharge-form-section .btn-submit {
  background: linear-gradient(180deg, #0C3C67, #00213D) 0 0 no-repeat padding-box;
  border: 0;
  border-radius: 8px;
  box-shadow: none;
  color: #fff;
  display: block;
  font-size: 16px;
  font-weight: 600;
  height: 48px;
  line-height: 20px;
  margin: 30px auto 0;
  text-align: center;
  transition: all .5s ease-out;
  width: 220px;
}

.one-click-checkout-suite {
  padding: 0 10px;
}
.one-click-checkout-suite h4{
  font-weight: 400;
}
.one-click-checkout-suite .right-side img{
  padding: 0;
}
.one-click-checkout-suite .left-side h3{
  padding-top: 0;
  margin-top: 0;
 
 
}
.pointer {
  cursor: pointer;
}
/* Desktops and laptops ----------- */
@media only screen and (min-width : 1024px) {
  .one-click-checkout-suite {
    padding: 0 100px;
  }
  .banner-offer-button-container{
    display: none;
  }
  .kwik-checkout-cod .home-p ul{
    display: inline-block;
  }
  .kwik-checkout-cod .home-p ul .list1{
    float: left;
  }
  .kwik-checkout-cod .home-p ul .list2{
    float: right;
    margin-left: 70px;
  }
}
/* iPads (portrait and landscape) ----------- */
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) {
  .banner-offer-button-container{
    display: none;
  }
}
/* Large screens ----------- */
@media only screen and (min-width : 1824px) {
  .one-click-checkout-suite {
    padding: 0 100px;
  }
.banner-offer-button-container{
  display: none;
}
}

input.kwik-mob-number::-webkit-outer-spin-button,
input.kwik-mob-number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media screen and (min-width: 1401px) {
  .container-fluid {
    max-width: 1650px;
  }
.codbtn{
  margin-right: 20px;
}
  .cod-banner-team {
    margin-left: -190px;
    padding: 10px 30px 45px 180px;
  }

  .exclusive-offer-container {
    max-width: 1300px;
  }

  .offer-button {
    width: 280px;
  }
  .banner-offer-button-container{
    display: none;
  }
}

@media only screen and (min-width:280px) and (max-width:768px) {
  .onclick-checkoutapp-form{
    margin: 0 auto;
    padding: 0 30px;
  }
  .checkooutcod-section-two .sec-width{
    padding: 10px;
  }
  .checkooutcod-section-two .sec-width .left-side h3{
    font-size: 28px;
    text-align: center;
  }
  .kwik-checkout-cod .b-heading {
    color: #000;
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
}
.exclusive-offer-button-container{
  margin-top: 10px;
  text-align: center;
}
.exclusive-offer-paragraph {
  max-width: 400px;
  font-size: 28px;
  margin-top: 30px;
  font-weight: 500;
  text-align: center;
}
.checkooutcod-section-two{
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
}
.checkooutcod-section-two .inner h2 {
  color: #F79D1C;
  font-size: 30px;
}
.checkooutcod-section-two .inner p {
  font-size: 22px;
}
.checkooutcod-section-two .sec-width{
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
}
.smart-cod-offer .offer-img{
  text-align: center;
}
.smart-cod-paragraph {
  font-size: 30px;
  font-weight: 600;
}
.smart-cod-text p {
  font-size: 18px;
}
.smart-cod-text{
  text-align: center;
}
.one-click-checkout-suite .left-side h3{
  padding-top: 0;
  margin-top: 0;
  text-align: center;
  font-size: 26px;
  line-height: 30px;
}
.one-click-checkout-suite .reverse-mobile-section{
  flex-direction: column-reverse;  
}
.kwik-checkout-cod .checkcod {
  padding-left: 10px;
  padding-right: 0;
}
.turbocharge-form-section .form-title h2 {
  color: #011220;
  font-size: 30px;
  font-weight: 600;
}

.turbocharge-form-section .form-title p {
  font-size: 18px;
}
.banner-offer-button-container .top-button {
  background-color: #0C3C67;
  background-image: linear-gradient(to bottom, #00213D 0%, #0C3C67 100%);
  padding: 8px 25px;
  border-radius: 6px;
  font-size: 16px;
  translate: 0 15px;
  border: none;
  color: #fff;
  width: 300px;
  margin-bottom: 10px;
}
.kwik-checkout-cod .checkcod h2{
  margin-top: 12px;
}
}