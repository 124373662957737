/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/

.header {
  transition: all 0.5s;
  z-index: 997;
  /* background: var(--primary); */
  background: #020a2f91;
  padding-left: 80px;
  padding-right: 80px;
  height: 72px;

  top: 0;
}

.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background: #020a2f91
}

.header .logo {
  transition: 0.3s;
}

.header .logo img {
  max-height: 40px;
  margin-right: 8px;
}

.header .mobile-icon {
  display: none;
}

/* .header .navbar  {
    height: 72px;
} */
.header .navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
  gap: 10px;
}

.header .navbar ul li {
  position: relative;
}

.header .navbar a {
  color: var(--font-one);
  font-weight: 600;
  transition: 0.3s;
  padding: 10px 20px;
}

.header .navbar a:hover,
.header .navbar a:focus {
  color: var(--font-one);
}


.header .navbar ul li.dropdown a:after {
  content: "";
  width: 14px;
  height: 14px;
  background: transparent linear-gradient(180deg, #000428 0%, #000428 46%, #133C6D 100%) 0% 0% no-repeat padding-box;
  display: inline-block;
  transform: rotate(45deg);
  position: absolute;
  bottom: -19px;
  left: calc(50% - 14px);
  border-radius: 1px;
  z-index: 11;
  opacity: 0;
  transition: all ease-in-out 0.4s;
  box-shadow: -2px -2px 3px -3px #d3d3d4;
}

.header .navbar ul li.dropdown:hover>a:after {
  opacity: 1;
}


.header a.contact {
  background: var(--button);
  padding: 5px 15px !important;
  border-radius: 5px;
}

.header .navbar a.contact:hover {
  color: var(--primary) !important;
}

.header .navbar ul li ul {
  display: flex;
  flex-direction: column;
  gap: 0px;
  position: absolute;
  left: 0px;
  top: 35px;
  margin: 0;
  background: transparent linear-gradient(180deg, #000428 0%, #000428 46%, #133C6D 100%) 0% 0% no-repeat padding-box;
  border-radius: 20px;
  min-width: 240px;
  align-items: flex-start;
  z-index: 99999;
  overflow: hidden;
  max-height: 0px;
  transition: max-height .45s ease-out;
}




.header .navbar ul li:hover ul.show {
  max-height: 900px;
  transition: max-height .15s ease-in;
}

.header .navbar ul li ul li {
  width: 100%;
}

.header .navbar ul li ul li a {
  font-weight: 600;
  transition: 0.3s;
  display: flex;
  gap: 0px;
  color: #F3F5FB;
  padding: 0px 16px;
}

.header .navbar ul li ul li a:hover {
  color: var(--font-one);
}

.header .navbar ul li ul li a .icon {
  width: 33px;
  height: 63px;
  border-radius: 13px 27px 14px 14px;
  background-repeat: no-repeat !important;
  background-position: 0px 14px !important;
}

.header .navbar ul li ul li a:hover .icon {
  background-position: -32px 14px !important;
}

.header .navbar ul li ul li a h4 {
  align-self: center;

  font-size: 16px;
  line-height: 44px;
  padding-left: 11px;
  padding-top: 5px;
  font-weight: 500;
  color: #F3F5FB;
  opacity: 0.7;
}

.header .navbar ul li ul li a:hover h4 {
  opacity: 1;
}



.header .mobile-nav-show {
  color: #fff;
  font-size: 28px;
  cursor: pointer;
  line-height: 0;
  transition: 0.5s;
  z-index: 9999;
  margin: 0 10px 0 10px;
}

.header .mobile-nav-hide {
  color: #fff;
  font-size: 32px;
  cursor: pointer;
  line-height: 0;
  transition: 0.5s;
  position: fixed;
  right: 20px;
  top: 20px;
  z-index: 9999;
}


@media (min-width: 840px) {

  .mobile-nav-show,
  .mobile-nav-hide {
    display: none;
  }
}


@media (max-width: 1366px) {
  .header {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 1100px) {
  .header .navbar ul li a {
    padding: 0 10px;
  }
}

@media (max-width: 880px) {
  .header .navbar ul li a {
    padding: 0 5px;
  }
}

.header .navbar .contactive {
  padding: 11px;
  border-radius: 10px;
  background: transparent linear-gradient(180deg, #133d6e, #010723) 0 0 no-repeat padding-box;
}

/*--------------------------------------------------------------
  # Mobile Navigation
  --------------------------------------------------------------*/
@media (max-width: 839px) {
  .header .navbar {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 400px;
    bottom: 0;
    transition: 0.3s;
    z-index: 9997;
    height: auto;
    display: none;
  }

  .header .navbar.show {
    display: block;
  }

  .header .navbar ul {
    position: absolute;
    inset: 0;
    padding: 50px 20px 20px 20px;
    margin: 0;
    background: rgba(0, 0, 0, 0.9);
    overflow-y: auto;
    transition: 0.3s;
    z-index: 9998;
    display: block;

  }

  .header .navbar ul li {
    position: relative;
  }

  .header .navbar a {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .header .navbar ul li a {
    padding: 10px 5px;
  }

  .header .navbar a:hover,
  .header .navbar a:focus {
    color: var(--primary);
  }

  .header a.contact {
    background: var(--primary);
    border-radius: 0;
  }

  .header .navbar a.contact:hover {
    color: var(--button) !important;
  }

  .header .navbar ul li ul {
    position: static;
    background: transparent;
    padding: 0px 10px;
  }

  .header .navbar ul li ul li a {
    padding: 0;
  }

  .header .navbar ul li ul li a:hover {
    background: none;
  }

  .header .navbar ul li ul li a .icon {
    display: none;
  }

  .header .navbar ul li ul li a h4 {
    color: var(--font-one);
    padding-top: 0;
    font-size: 13px;
    line-height: 22px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
}

.head-topbg {
  background: transparent linear-gradient(180deg, #133C6D 0%, #000428 100%) 0% 0% no-repeat;
}

.top-headersection {
  align-items: center;
  display: flex;
  justify-content: center;
  color: #fff;
}

.top-headersection button {
  background-color: #f09819;
  border: none;
  border-radius: 5px;
  padding: 5px 15px;
}