


.success-story{
    margin-top: -130px;
    position: relative;
    background: transparent linear-gradient(180deg, #133C6D 0%, #000428 64%, #000428 100%) 0% 0% no-repeat padding-box;
    border-radius: 47px 125px 0 0;
    padding-bottom: 60px;
    padding-left: 13%;
}
.success-story .container-fluid{padding-right: 0;}
.success-story .section-heading{
    text-align:left;
}

.success-story .items{
    justify-content:center;
}

.success-story .items .item {
    margin: 5px;
    width: 290px;
    max-width: 290px;
    display: flex !important;
    flex-direction: column;
    padding-left: 5px;
    padding-right: 5px;
}
.success-story .items .item img{
    height: auto;
    width:100%;
    margin: 0 auto;
    border-radius: 20px;
}
.success-story .items .item p{
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: var(--font-one);
    text-align: left;
    width: 100%;
    margin: 0 auto;
    padding-left: 5px;
    padding-top: 12px;
    padding-bottom: 2px;
}

.success-story .items .item a{
    font-size: 12px;
    font-weight: 400;
    line-height: 28px;
    color: #fff;
    padding-left: 5px;
}
.success-story .items .item a:hover{
    color: var(--primary);
}



@media (max-width: 1200px) {
    .success-story{
        padding-left: 0;
    }
}

@media (max-width:576px) {
    .success-story .section-heading{
        text-align: center;
    }
}