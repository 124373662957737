/* wrap business */
.supercharge-business {
    background: #E8F2FF;
    padding-top: 40px;
    /* padding-bottom:  80px; */
}
.supercharge-business h2 {
    font-size: 44px;
    line-height: 58px;
    text-align: center;
    font-weight: 700;
    color: var(--font-color);
    padding-bottom: 40px;
    margin-top: 15px;
}

.supercharge-business h2 span {
    color: #000;
}


.supercharge-business .topleft{
    background: transparent linear-gradient(121deg, #092158 0%, #040C35 100%) 0% 0% no-repeat padding-box;
    border-radius: 100px 20px 20px 20px;
    width:392px;
}
.supercharge-business .topright{
    background: transparent linear-gradient(180deg, #000C4A 0%, #194C87 100%) 0% 0% no-repeat padding-box;
    border-radius: 20px 100px 20px 20px;
    width:592px;
}
.supercharge-business .bottomleft{
    background: transparent linear-gradient(180deg, #000C4A 0%, #194C87 100%) 0% 0% no-repeat padding-box;
    border-radius: 20px 20px 20px 100px;
    width:592px;

}
.supercharge-business .bottomright{
    background: transparent linear-gradient(121deg, #092158 0%, #040C35 100%) 0% 0% no-repeat padding-box;
    border-radius: 20px 20px 100px 20px;
    width:392px;

}


.supercharge-business.up-top {
    margin-top: -235px;
}




.supercharge-business .grid-row{
    background:#ffffff6e;
    border-radius: 40px 100px 40px 40px;
    max-width:90%;
    margin: 0 auto;
}
.supercharge-business .grid-items{
    max-width:1068px;
    margin: 0 auto;
    padding: 35px;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 10px;
}

.supercharge-business .grid-items .item{
    height: 278px;
    position: relative;
}
.supercharge-business .grid-items .item .img{
    position: absolute;
    bottom: 30px;
    right: 0;
}


.supercharge-business .grid-items .item .content{
    position: absolute;
    padding-left: 25px;
    width: 235px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.supercharge-business .grid-items .item .content h4{
    font-size: 22px;
    line-height: 30px;
    font-weight: 600;
    color: var(--font-one);
}
.supercharge-business .grid-items .item .content span{
    font-size: 13px;
    opacity: .8;
    color: var(--font-one);
}
.supercharge-business .grid-items .item .content a{
    font-size: 16px;
    color: var(--font-one);
    display: block;
    transition: 0.3s all;
}
.supercharge-business .grid-items .item .content a:hover {
    color: var(--primary);
}



.supercharge-business .grid-items .item.topleft .img{

}
.supercharge-business .grid-items .item.topleft .content{
    width: 150px;
}

.supercharge-business .grid-items .item.topright .img{
    left: 20px;
    right:unset;
}
.supercharge-business .grid-items .item.topright .content{
    right: 20px;
    left:unset;
    padding-left: 0;
}

.supercharge-business .grid-items .item.bottomleft .img{
    left: 20px;
    right:unset;
}
.supercharge-business .grid-items .item .img img{
    max-width: 100%;
}
.supercharge-business .grid-items .item.bottomleft .content{
    right: 20px;
    left:unset;
    padding-left: 0;
}

.supercharge-business .grid-items .item.bottomright .img{}
.supercharge-business .grid-items .item.bottomright .content{
    width: 150px;
}



@media (max-width: 1210px) {
    .supercharge-business .grid-row{
        max-width: 100%;
    }
    .supercharge-business .grid-items{
        padding: 0;
        /* justify-content: center; */
    }
}


@media (max-width: 1020px) {
    .supercharge-business .grid-items{
        display: grid;
        grid-template-columns: auto;
    }
    .supercharge-business .grid-items .item{
        width: 100%;
        border-radius: 0;
    }

    .supercharge-business .grid-items .img{
        right:0px !important;
        left:unset !important;
    }
    .supercharge-business .grid-items .content{
        left:0px !important;
        right:unset !important;
        padding-left: 25px !important;
        width: auto !important;
    }
    
}

@media (max-width: 676px) {
    .supercharge-business h2{
        font-size: 30px !important;
        line-height: 42px !important;
    }
    .supercharge-business .grid-items .item .content{
        width: 100% !important;
        margin-top: 20px;
        text-align: center;
        padding-left: 0 !important;
        justify-content: unset;
    }
    .supercharge-business .grid-items .item .img{
        width: 200px !important;
        left: 0 !important;
        right: 0 !important;
        margin: 0 auto;
    }
}
