.product-section-one {
    background: transparent linear-gradient(359deg, #000428 0%, #000428 38%, #133C6D 100%) 0% 0% no-repeat padding-box;
    border-radius: 79px 79px 0 0;
    margin-top: -100px;
    padding-top: 100px;
    padding-bottom: 230px;
}

.product-stats {}

.product-stats .items {
    margin: 0 auto;
    display: flex;
    gap: 25px;
    max-width: 860px;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    row-gap: 30px;
    flex-wrap: wrap;
}

.product-stats .items .item {
    width: 188px;
    margin: 0 auto;
    min-height: 220px;
    display: flex;
    gap: 15px;
    max-width: 100%;
    background: transparent linear-gradient(180deg, #BCD9FF 0%, #B2D4FF 100%) 0% 0% no-repeat padding-box;
    border-radius: 20px 40px 20px 20px;
    flex-direction: column;
    padding: 15px;
    min-height: 235px;
}

.product-stats .items .item .leftbox {
    background: #F7FAFD 0% 0% no-repeat padding-box;
    border-radius: 13px 27px 14px 14px;
    width: 153px;
    align-self: center;
    text-align: center;
    padding: 15px;
    height: 95px;
}

.product-stats .items .item .leftbox img {
    max-width: 100%;
}

.product-stats .items .item .rightbox {
    /* align-self: center; */
}

.product-stats .items .item .rightbox h4 {
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #004B8D;
    /* text-align: left; */
    margin: 0;
    min-height: 44px;
}

.product-stats .items .item .rightbox span {
    /* text-align: left; */
    font-size: 27px;
    line-height: 44px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #000C4A;
}



.product-section-two {
    background: transparent linear-gradient(188deg, #133C6D 0%, #071942 50%, #000428 100%) 0% 0% no-repeat padding-box;
    border-radius: 0px 163px 0px 0px;
    min-height: 500px;
    margin-top: -155px;
    padding-bottom: 230px;
    /* padding-bottom:5px; */
}

.product-section-two .items {
    /* width: 1004px;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    gap: 60px;
    row-gap: 30px; */
    justify-content: center;
    align-items: center;
    row-gap: 40px;
}

.product-section-two .items.secound {
    max-width: 700px;
    margin-left: auto !important;
    margin-right: auto !important;
}

.product-section-two .items .item {
    background: transparent linear-gradient(180deg, #ffffff42 0%, #000c4a70 100%) 0% 0%;
    border-radius: 12px;
    max-width: 308px;
    display: flex;
    gap: 30px;
    row-gap: 30px;
    flex-direction: column;
    padding: 30px 15px;
    margin: 0 auto;
    min-height: 342px;
}

.product-section-two .items .item .img-wrapper {
    width: 100px;
    height: 100px;
}

.product-section-two .items .item .img-wrapper img {
    width: 100%;
}


.product-section-two .items .item h4 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    color: var(--primary);
}

.product-section-two .items .item p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    color: var(--font-one);
}

.product-section-three {
    background: transparent linear-gradient(188deg, #133C6D 0%, #071942 50%, #000428 100%) 0% 0% no-repeat padding-box;
    border-radius: 0px 163px 0px 0px;
    min-height: 500px;
    margin-top: -155px;
    padding-bottom: 130px;
}

.product-section-three .items {
    /* width: 1004px; */
    /* max-width: 100%;
    margin: 0 auto;
    display: flex;
    gap: 10px;
    row-gap: 30px; */
    justify-content: center;
    align-items: center;
}

.product-section-three .items .item {
    max-width: 350px;
    margin: 0 auto;
    display: flex;
    gap: 30px;
    row-gap: 30px;
    flex-direction: column;
    padding: 30px 15px;
}

.product-section-three .items .item .img-wrapper {
    max-width: 350px;
}

.product-section-three .items .item .img-wrapper img {
    width: 100%;
}


.product-section-three .items .item h4 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    color: var(--primary);
}

.product-section-three .items .item p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    color: var(--font-one);
}




.product-section-four {
    background: transparent linear-gradient(0deg, #E8F2FF 0%, #E8F2FF 0%, #7EB4FF 100%) 0% 0% no-repeat padding-box;
    border-radius: 100px 0 0 0;
    min-height: 500px;
    margin-top: -155px;
    padding-bottom: 200px;
}

.product-section-four .items {
    /* width: 1004px; */
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    gap: 30px;
    row-gap: 30px;
    justify-content: center;
    align-items: center;
}

.product-section-four .items .item {
    max-width: 180px;
    display: flex;
    gap: 30px;
    row-gap: 15px;
    flex-direction: column;
    padding: 0;
    justify-content: center;
    text-align: center;
    position: relative;
}

.product-section-four .items .item:after {
    content: "";
    width: 100%;
    height: 100%;
    left: 134px;
    top: 0px;
    position: absolute;
    background-repeat: no-repeat !important;
    z-index: 1;
}

.product-section-four .items .item:nth-child(odd) {
    margin-top: -70px;
}

.product-section-four .items .item:nth-child(odd)::after {
    background: url("./images/arrow_1_1696570370.svg") !important;
    background-repeat: no-repeat !important;
}

.product-section-four .items .item:nth-child(even)::after {
    background: url("./images/arrow_4_1696571735.svg") !important;
    background-repeat: no-repeat !important;
    top: unset;
    bottom: -70px;
    background-size: 112px 112px;
    left: 128px;
    z-index: 0;
}

.product-section-four .items .item:last-child::after {
    display: none;
}



.product-features {
    min-height: 100vh;
    padding-left: 80px;
    padding-right: 80px;
    margin-bottom: 0px;
    margin-top: 60px;
    height: auto;
    position: relative;
}

.product-features .container-fluid {
    position: relative;
    /* min-height: 100vh; */
}

.product-features .section-header {
    /* max-width: 542px; */
    /* padding-left: 70px; */
}
.product-features .section-header .description {
    text-align: center;
}

.product-features .section-header h3 {
    text-align: center;
}

.product-features .wrapper {
    /* background: transparent linear-gradient(357deg, #000428 0%, #000428 46%, #133C6D 100%) 0% 0% no-repeat padding-box;
    min-height: 302px; */
    margin-top: 66px;
    margin-bottom: 66px;
    /* width: 60%; */
    /* position: sticky; */
    display: flex;
}

.product-features .container-fluid {
    position: relative;
}

/* .product-features.fixed{
    position: fixed;
    top:0;
    height:100vh;
} */

.product-features.sticky {
    position: sticky;
    top: 0;
    height: 100vh;
}

.product-features .wrapper .w-50 {
    width: 50%;
    border: 1px solid red;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 100vh;
    overflow: auto;
}


#product-features-container {
    transition: 0.3s opacity;
}

#screens {
    position: sticky;
    position: -webkit-sticky;
    height: 100vh;
    align-self: auto;
    display: flex;
    align-items: center;
    top: 40px;
    justify-content: center;
    padding-right: 1rem;
    width: 50%;
}

.item-frame {
    /* transition: right .45s ease-out; */
    z-index: 1;
    display: none;
    /* opacity: 0; */
    /* visibility: hidden; */
}

.item-frame.active {
    display: block;
    z-index: 999;
    /* animation: myfirst 0.5s 1; */
    /* transition: right .15s ease-in; */
    /* opacity: 1; */
    /* visibility: visible; */
}


@keyframes myfirst {
    0% {
        right: -200px;
    }

    25% {
        right: -175px;
    }

    50% {
        right: -150px;
    }

    75% {
        right: -100px;
    }

    100% {
        right: 12px;
    }
}






.product-features .img-wrapper {
    width: auto;
    max-width: 250px;
    z-index: 9999;
    float: right;
    position: relative;
    overflow: hidden;
}

.product-features .img-wrapper-big {
    max-width: 410px;
}

.product-features .img-wrapper-big #item0-frame {
    display: none;
}


.img-wrapper .frame-big {
    display: none;
}

.img-wrapper .frame {
    display: block;
}

.img-wrapper-big .frame-big {
    display: block;
}

.img-wrapper-big .frame {
    display: none;
}

.img-frame {
    position: absolute;
    max-width: 226px;
    top: 35px;
    right: 12px;
    /* right: -228px; */
    /* display: none; */
}

.img-frame img {
    width: 100%;
    border-radius: 25px;
}

.product-features .img-wrapper img {
    width: 100%;
}

.product-features .mobile-img-frame {
    width: 100%;
    border-radius: 25px;
    display: none;
    text-align: center;
    margin-bottom: 40px;

}

.product-features .mobile-img-frame img {
    border-radius: 25px;
    max-width: 100%;
}



.product-features .item {
    display: flex;
    margin-top: 200px;
    margin-bottom: 400px;
    opacity: 1;
    transition: 0.3s all;
    /* height:99vh; */
    /* height: calc(100vh - 30px); */
    justify-content: center;
    /* margin-top: -100px; */
}

.product-features .item.active {
    opacity: 1;
}

.product-features .leftbox {
    padding-left: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.product-features h4 {
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    color: var(--primary);
}

.product-features p {
    font-weight: 300;
    font-size: 24px;
    line-height: 30px;
    color: var(--font-one);
}

.product-features .rightbox {
    padding-left: 70px;
    margin-top: 130px;
}

.product-features .circle {
    width: 24px;
    height: 24px;
    border: 1px solid #fff;
    border-radius: 50%;
    background: transparent;
    display: flex;
    justify-content: center;
    position: relative;
    right: 212px;
    top: 260px;
    z-index: -1;
    display: none !important;
}


@keyframes circleScale {
    from {
        transform: scale(1);
    }

    to {
        transform: scale(1.2);
    }
}

.product-features .inner-circle {
    width: 16px;
    height: 16px;
    border: 2px solid transparent;
    border-radius: 50%;
    background: var(--primary);
    align-self: center;
    transition: all 0.3s;
    animation-duration: 1s;
    animation-name: circleScale;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}


@keyframes circleLine {
    from {
        border-top: 1px dotted #fff;
    }

    to {
        border-top: 1px dotted var(--primary);
    }
}

@keyframes indeterminateAnimation {
    0% {
        transform: translateX(0) scaleX(0);
    }

    40% {
        transform: translateX(0) scaleX(0.4);
    }

    100% {
        transform: translateX(100%) scaleX(0.5);
    }
}

.product-features .circle::before {
    height: 1px;
    border-top: 2px dotted #fff;
    position: absolute;
    left: 24px;
    content: '';
    width: 300px;
    top: 11px;
    transition: all 0.3s;

    /* animation-duration: 1s;
    animation-name: circleLine;
    animation-iteration-count: infinite;
    animation-direction: alternate; */
}

.product-features .circle::after {
    height: 1px;
    border-top: 2px dotted var(--primary);
    position: absolute;
    left: 24px;
    content: '';
    width: 300px;
    top: 11px;
    transition: all 0.3s;

    animation: indeterminateAnimation 3s infinite linear;
    transform-origin: 0% 100%;
}




.product-funnel {}

.product-funnel .img-wrapper {
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
}

.product-funnel .img-wrapper img {
    max-width: 100%;
}



.product-solutions {
    background: transparent linear-gradient(188deg, #133C6D 0%, #071942 50%, #000428 100%) 0% 0% no-repeat padding-box;
    border-radius: 0px 163px 0px 0px;
    margin-top: -160px;
    padding-bottom: 230px;
}

.product-solutions .wrapper {
    max-width: 1100px;
    margin: 0 auto;

}

.product-solutions .item {
    position: relative;
}

.product-solutions .item .leftbox {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.product-solutions .item .rightbox {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.product-solutions h4 {
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    color: var(--primary);
}

.product-solutions p {
    font-weight: 300;
    font-size: 24px;
    line-height: 30px;
    color: var(--font-one);
}

.product-solutions .item .img-wrapper {
    max-width: 100%;
    z-index: 9;
}

.product-solutions .item .leftbox .img-wrapper {
    text-align: left;
}

.product-solutions .item .rightbox .img-wrapper {
    text-align: right;
}

.product-solutions .item .img-wrapper img {
    width: 100%;
    max-width: 436px;
}

.product-solutions .wrapper .item::before {
    content: "";
    width: 100%;
    height: 100%;
    left: 134px;
    top: 0px;
    position: absolute;
    background-repeat: no-repeat !important;
    z-index: 1;
    /* background: url("./kwik-advantage/path-top.png") !important; */
    background-repeat: no-repeat !important;
}

/* .product-solutions .wrapper .item:nth-child(odd) {
    margin-top: -70px;
} */

.product-solutions .wrapper .item:nth-child(even)::before {
    background: url("./kwik-advantage/path-top.png") !important;
    background-repeat: no-repeat !important;
    top: -170px;
    left: 327px;
}

.product-solutions .wrapper .item:nth-child(odd)::before {
    background: url("./kwik-advantage/path-bottom.png") !important;
    background-repeat: no-repeat !important;
    top: -100px;
    left: 310px;
}

.product-solutions .wrapper .item:first-child::before {
    display: none;
}

.product-solutions .feature-wrapper {
    background: transparent linear-gradient(356deg, #000428 0%, #000428 46%, #133C6D 100%) 0% 0% no-repeat padding-box;
    min-height: 600px;
    display: flex;
    margin-left: 13%;
    margin-top: 60px;
}

.product-solutions .feature-wrapper .inner-wrapper {
    max-width: 900px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 22px;
    padding-left: 140px;
    text-align: center;
}

.product-solutions .feature-wrapper p span {
    color: var(--primary);
}


.product-cod-works {
    background: transparent linear-gradient(0deg, #E8F2FF 0%, #E8F2FF 0%, #7EB4FF 100%) 0% 0% no-repeat padding-box;
    border-radius: 100px 0px 100px 100px;
    margin-top: -160px;
    padding-bottom: 230px;
}

.product-cod-works .section-header h3 {
    color: #000C4A;
}

.product-cod-works .items {
    /* width: 1004px; */
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    gap: 100px;
    row-gap: 30px;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
}

.product-cod-works .items .item {
    max-width: 330px;
    min-width: 300px;
    display: flex;
    gap: 30px;
    row-gap: 15px;
    flex-direction: column;
    padding: 0;
    justify-content: center;
    text-align: center;
    position: relative;
}

.product-cod-works .items .item:after {
    content: "";
    width: 100%;
    height: 100%;
    left: 330px;
    top: 0px;
    position: absolute;
    background-repeat: no-repeat !important;
    z-index: 1;
}

.product-cod-works .items .item:nth-child(odd) {
    margin-top: -70px;
}

.product-cod-works .items .item:nth-child(odd)::after {
    background: url("./images/arrow_1_1696570370.svg") !important;
    background-repeat: no-repeat !important;
}

.product-cod-works .items .item:nth-child(even)::after {
    background: url("./images/arrow_4_1696571735.svg") !important;
    background-repeat: no-repeat !important;
    top: 260px;
    left: 300px;
    background-size: 112px 112px;
    z-index: 0;
}

.product-cod-works .items .item:last-child::after {
    display: none;
}

.product-cod-works .items .item img {
    max-width: 100%;
}

.product-cod-works .items .item h4 {
    color: #000C4A;
}


.product-cod-kai {
    background: #000428;
    margin-top: -160px;
    padding-bottom: 230px;
    position: sticky;
}

.product-cod-kai .section-header h4 {
    font-size: 40px;
    line-height: 58px;
    font-weight: 700;
    text-align: center;
    color: var(--primary);
}

.product-cod-kai .wrapper {
    max-width: 1000px;
    margin: 0 auto;

}

.product-cod-kai .wrapper .section-header h3 {
    text-align: left;
}

.product-cod-kai .wrapper .items {
    /* background: transparent linear-gradient(358deg, #000428 0%, #000428 46%, #133C6D 100%) 0% 0% no-repeat padding-box; */
    /* min-height: 452px; */
    display: flex
}



.product-cod-kai .mobile-img-frame {
    width: 100%;
    border-radius: 25px;
    display: none;
    text-align: center;
    margin-bottom: 40px;

}

.product-cod-kai .mobile-img-frame img {
    border-radius: 25px;
    max-width: 100%;
}


#kai-screens {
    position: sticky;
    position: -webkit-sticky;
    height: 100vh;
    align-self: auto;
    display: flex;
    align-items: center;
    top: 0;
    justify-content: flex-end;
    padding-right: 1rem;
    width: 50%;
}


.product-cod-kai .img-frame {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 464px;
    height: 100%;
    display: none;
    position: absolute;
    top: 0;
}

.product-cod-kai .img-frame img {
    width: 100%;
    border-radius: 25px;
}

.product-cod-kai .img-wrapper img {
    width: 100%;
}

.product-cod-kai .frame {
    max-width: 464px;
    position: absolute;
    top: 0px;
    height: 100%;
    right: 3px;
}

.product-cod-kai .img-frame.active {
    display: flex;
}

#kai-screens {
    position: sticky;
    position: -webkit-sticky;
    height: 100vh;
    align-self: auto;
    display: flex;
    align-items: center;
    top: 0;
    justify-content: flex-end;
    padding-right: 1rem;
    width: 50%;
    overflow: hidden;
}

.product-cod-kai .item {
    margin-top: 400px;
    margin-bottom: 400px;
    max-width: 90%;
}

.product-cod-kai .item p {
    font-size: 32px;
    line-height: 36px;
    font-weight: 600;
}

.product-cod-kai .item .leftbox {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.product-cod-kai .img-wrapper {
    max-width: 100%;
    width: 100%;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    height: 100vh;
    overflow: hidden;
    position: relative;
}




@media only screen and (max-width: 1024px) {
    .product-section-one {
        margin-top: -200px;
    }

    .product-section-four .items .item:nth-child(odd)::after {
        background-size: 98px !important;
    }

    .product-section-four .items .item:nth-child(even)::after {
        background-size: 98px !important;
        left: 150px;
    }

    .product-cod-works .items {
        gap: 30px;
        row-gap: 60px;
    }

    .product-features {
        padding-left: 10px;
        padding-right: 10px;
    }

    .product-features .container-fluid {
        padding-left: 5px;
        padding-right: 5px;
    }

    .product-features .leftbox {
        padding-top: 30px;
        padding-left: 30px;
    }

}

@media only screen and (max-width: 860px) {
    /* .product-section-four .items .item:nth-child(odd)::after {
        background-size: 98px !important;
    }
    .product-section-four .items .item:nth-child(even)::after {
        background-size: 98px !important;
        left: 150px;
    } */

    .product-solutions .wrapper {
        display: grid;
        row-gap: 80px;
    }

    .product-solutions .item {
        position: relative;
        display: flex;
        row-gap: 40px;
        text-align: center;
        flex-direction: column-reverse;
    }

    .product-solutions .item:nth-child(even) {
        flex-direction: column;
    }

    .product-solutions .wrapper .item::before {
        display: none;
    }

    .product-solutions .feature-wrapper {
        margin-left: 0;
    }

    .product-solutions .feature-wrapper .inner-wrapper {
        padding-left: 0;
        text-align: center;
    }

    .product-cod-works .items {
        display: grid;
    }

    .product-cod-works .items .item::after {
        display: none;
    }

    .product-cod-works .items .item {
        width: 100%;
    }

    .product-cod-works .items .item:nth-child(odd) {
        margin-top: 0;
    }



    .product-section-one .items {
        max-width: 800px;
    }

    .product-section-four .items .item:nth-child(odd) {
        margin-top: 0px;
    }

    .product-section-four .items {
        gap: 20px;
        flex-wrap: wrap;
    }

    /* .product-section-four .items .item{
        max-width: 150px;
    } */
    .product-section-four .items .item:after {
        display: none;
    }

    .product-features .section-header {
        padding-top: 0;
    }

    .product-features .section-header h3 {
        text-align: unset;
    }

    .product-features .leftbox {
        width: 100% !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 50px;
    }

    .product-features .img-frame {
        top: 35px;
    }

    .product-features .item {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .product-features .mobile-img-frame {
        display: block;
    }

    #screens {
        display: none;
    }


}

@media only screen and (max-width:768px) {
    .product-features .section-header {
        padding-left: 0;
    }

    .product-features .wrapper {
        flex-direction: column-reverse;
    }

    #screens {
        position: relative;
        height: auto;
        width: 100%;
        justify-content: center;
    }

    /* .product-features .img-wrapper{
        position: unset;
        margin: 0 auto;
    } */
    .product-features .circle {
        display: none;
    }


    .product-cod-kai .mobile-img-frame {
        display: block;
    }

    #kai-screens {
        display: none;
    }

    .product-cod-kai .item {
        max-width: 99%;
        margin: 0 auto;
        margin-bottom: 40px;
    }

    .product-cod-kai .item p {
        text-align: center;
    }

    .product-cod-kai .item .leftbox {
        flex-direction: column;
    }
    .ideal-popup-sticky-cta .ideal-popup-sticky-cta-expanded-content .subscribe-form {
        margin-top: 20px;
        display: block!important;
    }
}


@media only screen and (max-width: 420px) {
    .product-section-four .items {
        gap: 2px;
    }

    .product-section-four .items .item {
        max-width: 150px;
    }

    .product-features .leftbox {
        padding: 30px;
    }
}



.product-section-four .items .item .img-wrapper {
    max-width: 180px;
    text-align: center;
}

.product-section-four .items .item .img-wrapper img {
    width: 100%;
}


.product-section-four .items .item h4 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    color: var(--font-color);
}




/* product section merchant*/

.product-section-merchant .items {
    /* width: 1004px; */
    /* max-width: 100%;
    margin: 0 auto;
    display: flex;
    gap: 10px;
    row-gap: 30px; */
    justify-content: center;
    align-items: center;
}

.product-section-merchant .items .item {
    max-width: 350px;
    margin: 0 auto;
    display: flex;
    gap: 30px;
    row-gap: 30px;
    flex-direction: column;
    padding: 30px 15px;
}

.product-section-merchant .items .item .img-wrapper {
    max-width: 350px;
    text-align: center;
}

.product-section-merchant .items .item h4 {
    font-size: 24px;
    line-height: 24px;
    font-weight: 600;
    color: var(--primary);
    text-align: center;
}

.product-section-merchant .items .item p {
    font-size: 18px;
    line-height: 24px;
    font-weight: 300;
    color: var(--font-one);
    text-align: center;
}

.product-section-merchant .section-header {
    padding-top: 0;
}

.product-section-merchant .section-header p {
    color: var(--font-one);
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    font-weight: 300;
}

.shopbannerdesp {
    margin-top: 30px;
    margin-bottom: 30px !important;
}

.merc-exp {
    text-align: center;
}

.merchant-exp-btn {
    min-width: 130px;
    padding: 0px 20px;
    border-color: transparent;
    background: #f09819;
    color: var(--font-one);
    min-height: 45px;
    margin-top: 20px;
    border-radius: 8px;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 50px;
    font-weight: 600;
    transform: translate(0px);
    cursor: pointer;
}

.shopify-advantage .items {
    margin: 0 auto;
    display: flex;
    max-width: none;
    gap: 25px;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    row-gap: 30px;
    flex-wrap: wrap;
}

.shopify-advantage .items .item .rightbox h4 {
    font-size: 17px;
    line-height: 25px;
}

.shopify-boost-conversion {
    background: transparent linear-gradient(180deg, #133c6d, #000428 64%, #000428) 0 0 no-repeat padding-box;
    border-radius: 47px 125px 0 0;
    margin-top: -180px;
    padding-bottom: 60px;
    position: relative;
}

.shopify-boost-conversion .product-features p {
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
    color: var(--font-one);
}

.shopify-boost-conversion form {
    background: transparent linear-gradient(209deg, #f89b15, #f3ad47 73%, #f6e3ca 100%, #f6e6d0 0) 0 0 no-repeat padding-box;
    border-radius: 58px 58px 0 0;
    padding: 80px;
    width: 100%;
}

@media only screen and (max-width: 768px) {
    .shopify-boost-conversion form {
        background: transparent linear-gradient(209deg, #f89b15, #f3ad47 73%, #f6e3ca 100%, #f6e6d0 0) 0 0 no-repeat padding-box;
        border-radius: 58px 58px 0 0;
        padding: 10px;
        width: 100%;
        padding-top: 83px;
        padding-bottom: 180px;
        margin-bottom: -110px;
    }
}

.shopify-boost-conversion .form .form-wrapper {
    margin: 0 auto;
    max-width: 665px;
    padding-bottom: 60px;

}

.shopify-boost-conversion .form .custom-box {
    background: #e8f2ff 0 0 no-repeat padding-box;
    border-radius: 8px;
    min-height: 106px;
    padding: 16px;
}

.shopify-boost-conversion .btn-submit {
    background: transparent linear-gradient(304deg, #133d6e, #010723) 0 0 no-repeat padding-box;
    border: 0;
    border-radius: 8px;
    box-shadow: none;
    color: #fff;
    display: block;
    font-size: 16px;
    font-weight: 600;
    height: 48px;
    line-height: 20px;
    margin: 30px auto 0;
    opacity: 1;
    color: #fff;
    text-align: center;
    transition: .3;
    transition: all .5s ease-out;
    width: 220px;
}

.shopify-boost-conversion .product-features .section-header h3 {
    text-align: center;
    margin-top: 50px;

}

@media only screen and (max-width: 860px) {
    .shopify-boost-conversion .product-features .section-header {
        padding-top: 60px;
    }
}

.thank-you-shopify {
    margin-bottom: 25px;
}

.thank-you-shopify h1 {
    color: #fd7e00;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 42px;
}

.thank-you-shopify p {
    color: #feb300;
}

.contact-active {
    padding: 11px;
    border-radius: 10px;
    background: transparent linear-gradient(180deg, #133d6e, #010723) 0 0 no-repeat padding-box;
}

.contact-active:hover {
    color: #f79f21 !important;
}


.test-arrow {
    padding-left: 20px;
}

.test-arrow li {
    font-size: 20px;
    font-weight: 400;
}

.test-arrow p {
    font-size: 20px;
    font-weight: 400;
    margin-left: -25px;
    display: flex;
}

.test-arrow span {
    color: #f09819;
}

.test-arrow li::marker {
    color: #f09819;
}

.shopifyheder .section-header p {
    color: var(--font-one);
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    font-weight: 300;
}




.ideal-popup-sticky-cta[data-banner-state=expanded] {
    max-height: calc(100vh - 10px);
    overflow-y: auto;
    z-index: 99999;
}

@media (min-width: 600px) {
    .ideal-popup-sticky-cta {

        max-width: 500px;
        right: 0;
        z-index: 99999;
    }
}

.ideal-popup-sticky-cta {
    bottom: 0;
    position: fixed;
    text-align: left;
    transition: .5s ease-in-out;
    width: 100%;
    z-index: 20;
}

.ideal-popup-sticky-cta .ideal-popup-sticky-cta-expanded-content {
    background-color: var(--cl-color-background-02);
    color: var(--cl-color-text-01);
    display: none;
    padding: 0 1.5rem 1rem;
    text-align: center;
}

.ideal-popup-sticky-cta[data-banner-state=expanded] .ideal-popup-sticky-cta-expanded-content {
    display: block;

}

.ideal-popup-sticky-cta .ideal-popup-sticky-cta-expanded-content {
    background: #f89b15;
    border: 2px solid #fff;
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
    border-top-left-radius: 60px;
    border-top-right-radius: 0;
    color: #000;
    text-align: left;
}

.ideal-popup-sticky-cta .ideal-popup-sticky-cta-expanded-content .parag-content {
    padding: 54px 27px 0;
}

.ideal-popup-sticky-cta .ideal-popup-sticky-cta-expanded-content .parag-content h1 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 15px;
}

.ideal-popup-sticky-cta .ideal-popup-sticky-cta-expanded-content .parag-content p {
    font-size: 20px;
    line-height: 24px;
}


.ideal-popup-sticky-cta .ideal-popup-sticky-cta-expanded-content .parag-content button {
    background: transparent linear-gradient(319deg, #133d6e, #010723) 0 0 no-repeat padding-box;
    border-color: transparent;
    border-radius: 6px;
    color: var(--font-one);
    font-size: 16px;
    height: 40px;
    margin-top: 5px;
    margin-bottom: 20px;
    padding: 0 20px;
    width: auto;
    margin-left: 40px;
}

.ideal-popup-sticky-cta .ideal-popup-sticky-cta-static .cross-icon button {
    background: linear-gradient(#0c3c67, #00213d);
    border: none;
    border-radius: 50px;
    color: #fff;
    float: right;
    height: 22px;
    width: 22px;
    left: -11px;
    opacity: 1;
    position: relative;
    top: 25px;
    z-index: 9;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ideal-popup-sticky-cta .ideal-popup-sticky-cta-expanded-content .subscribe-form {
    margin-top: 20px;
    display: flex;
}

.ideal-popup-sticky-cta .ideal-popup-sticky-cta-expanded-content input {
    border: 0;
    border-radius: 6px;
    height: 36px;
}

.ideal-popup-sticky-cta .ideal-popup-sticky-cta-expanded-content input {
    background: #fff;
    color: #000c4a;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    max-width: 100%;
    padding-left: 22px;
    margin-right: 8px;
    margin-bottom: 7px;
    width: 190px;
}

.ideal-popup-sticky-cta .ideal-popup-sticky-cta-expanded-content ::placeholder {
    color: black;

}

.subscribe-form .field-error {
    border: 1px solid #ff0000 !important;
}

.field-error {
    border: 1px solid #ff0000 !important;
}

/* smart-cod-suite-faq */

.product-faq-section{
    background: #0000 linear-gradient(227deg,#e8f2ff,#d6e7ff) 0 0 no-repeat padding-box;
    border-radius: 3px 180px 20px 20px;
    margin-top: -158px;
    padding-bottom: 200px;
    position: relative;
}
.product-faq-section .wrapper{
    margin: 0 auto;
    max-width: 900px;
}
.product-faq-section .section-header h3 {
    color: #03306e;
    text-align: left;
}
/* kwikchat feature */

.kwikchat-feature .kw-adv{
    width: 1300px;
    font-size: 24px;
    line-height: 28px;
    font-weight: 300;
    margin-bottom: 200px;
    padding: 50px;
    display: flex;
    position: relative;
    margin-top: 150px;
    align-items: center;
    background: linear-gradient(-1deg, #000428 0.00%, #000428 45.59%, #133c6d 100.00%);
}
.kwikchat-feature .kw-adv .content{
    margin-right: 400px;
}
.kwikchat-feature .kw-adv img {
    position: absolute;
    right: -40px;
    max-width: 45%;
    /* bottom: -160px; */
}
.kwikchat-feature .kw-adv h3{
    font-weight: 400;
    font-size: 1.5rem;
}




 /* For mobile devices and Tablet */

 @media only screen and (min-width: 320px) and (max-width: 768px) {
    .kwikchat-feature .kw-adv {
        font-size: 24px;
        line-height: 28px;
        font-weight: 300;
        margin-bottom: 100px;
        padding: 30px;
        display: block;
        position: relative;
        margin-top: 0px;
        align-items: center;
        background: linear-gradient(-1deg, #000428 0.00%, #000428 45.59%, #133c6d 100.00%);
    }
    .kwikchat-feature .kw-adv .content {
        margin-right: 0;
    }
    .kwikchat-feature .kw-adv img {
        position: relative;
        right: 0;
        max-width: 100%;
        /* bottom: -160px; */
    }
    .kwikchat-feature .kw-adv h4{
        text-align: center;
    }
    .kwikchat-feature .kw-adv h3 {
        font-weight: 400;
        font-size: 1.3rem;
        text-align: center;
    }
    
    .kwikchat-feature .kw-adv ul{
        font-size: 20px;
    }
  } 