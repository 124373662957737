.network-effect{
    margin-top: -130px;
    padding-bottom: 200px;
    background: transparent linear-gradient(0deg, #E8F2FF 0%, #E8F2FF 0%, #7EB4FF 100%) 0% 0% no-repeat padding-box;
    border-radius: 93px 153px 0px 0px;  
}

.network-effect .section-header h3{
    color: #03306E;
}
.network-effect .section-header p {
    color: #000C4A;
}

.network-effect .items{
    width: 1004px;
    /* overflow-x: scroll; */
    max-width: 100%;
    background: #ABC9F0 0% 0% no-repeat padding-box;
    border-radius: 20px;
    margin: 0 auto;
    display: flex;
    gap: 25px;
    justify-content: center;
    height: 161px;
    align-items: center;
}
.network-effect .items .item{
    width: 295px;
    height: 96px;
    display: flex;
    gap: 15px;
    max-width: 100%;
}

.network-effect .items .item .leftbox{
    background: #F7FAFD 0% 0% no-repeat padding-box;
    border-radius: 13px 27px 14px 14px;
    width: 100px;
    align-self: center;
    text-align: center;
    padding: 15px;
    height: 95px;
}

.network-effect .items .item .rightbox{
    align-self: center;
}
.network-effect .items .item .rightbox h4{
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #004B8D;
    text-align: left;
    margin:0;
}
.network-effect .items .item .rightbox span{
    text-align: left;
    font-size: 40px;
    line-height: 44px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #000C4A;
}



@media (max-width: 775px) {
    .network-effect{
        height: auto;
    }
    .network-effect .items{
        padding-left: 0px;
        flex-direction: column;
        height: auto;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 20px;
        padding-right: 20px;
    }
}