.career-section-one{
    background: none;
    height:161px;
    position: relative;
    margin-top: -150px;
    z-index: 1;
}
.career-section-one .items{
    width: 1004px;
    /* overflow-x: scroll; */
    max-width: 100%;
    background: #D8E8FF 0% 0% no-repeat padding-box;
    border-radius: 20px;
    margin: 0 auto;
    display: flex;
    gap: 25px;
    justify-content: center;
    height: 161px;
    align-items: center;
}
.career-section-one .items .item{
    width: 295px;
    height: 96px;
    display: flex;
    gap: 15px;
    max-width: 100%;
}

.career-section-one .items .item .leftbox{
    background: #F7FAFD 0% 0% no-repeat padding-box;
    border-radius: 13px 27px 14px 14px;
    width: 100px;
    align-self: center;
    text-align: center;
    padding: 15px;
    height: 95px;
}

.career-section-one .items .item .rightbox{
    align-self: center;
}
.career-section-one .items .item .rightbox h4{
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #004B8D;
    text-align: left;
    margin:0;
}
.career-section-one .items .item .rightbox span{
    text-align: left;
    font-size: 40px;
    line-height: 44px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #000C4A;
}


@media (max-width: 960px) {
    .career-section-one .items{
        row-gap: 25px;
        padding-left: 10px;
    }
    .career-section-one .items .item .rightbox span{
        font-size: 30px;
    }
}



@media (max-width: 775px) {
    .career-section-one{
        height: auto;
    }
    .career-section-one .items{
        padding-left: 0px;
        flex-direction: column;
        height: auto;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 20px;
        padding-right: 20px;
    }
}


.career-section-two{
    background: transparent linear-gradient(18deg, #000428 0%, #000428 2%, #133C6D 100%) 0% 0% no-repeat padding-box;
    border-radius: 79px;
    border-radius: 79px 79px 0 0;
    position: relative;
    margin-top: -80px;
    padding-top: 95px;
    padding-bottom: 255px;
}
.career-section-two .slider2{
    margin-top:60px
}

.career-section-two .slider2 .slick-slider .slick-track, .career-section-two .slider2 .slick-slider .slick-list {
    padding-top: 50px !important;
    padding-bottom: 40px !important;
}

.career-section-two .slider2  .box-slider {
    transition: all .3s ease;
    /* background: transparent linear-gradient(206deg, #00315D 0%, #001B33 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #00000029;
    border-radius: 40px 74px 0px 40px;
    padding: 10px 30px 20px 0px; */
   
}
.career-section-two .slider2  .box-slider .item {
    transition: all .3s ease;
    opacity: .8;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    position: relative;
    /* max-width: 400px; */
}
.career-section-two .slider2 .box-slider .item p{
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: 0 auto;
}
.career-section-two .slider2  .slick-center .box-slider {
    -ms-transform: scale(1.75);
    transform: scale(1.75);
    z-index: 999;
   
}
.career-section-two .transform {
    -ms-transform: scale(1.25);
    transform: scale(1.25);
    z-index: 99;
   
}
.career-section-two .slider2 .slick-center .box-slider .item{
    opacity: 1;
    /* padding: 10px 30px 20px 0px; */
    /* padding-bottom: 30px; */
    /* max-width: 500px; */
}
.career-section-two .slider2 .box-slider .item img{
    max-width: 100%;
    margin: 0 auto;
    border-radius: 30px;
    box-shadow: 0px 3px 10px #00000029;
}



.career-section-three{
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px;
}

.career-section-three .bg{
    background:transparent;
    display: flex;
    position: relative;
    min-height: 400px;
}
.career-section-three .box-slider{
    padding-left:30px;
    padding-right:30px;
}
.career-section-three .item{
    position: relative;
    background: #000428 0% 0% no-repeat padding-box;
    border-radius: 40px 74px 40px 40px;
    display: flex;
    gap: 30px;
    padding: 30px;
}
.career-section-three .item img{
    background: #001B33 0% 0% no-repeat padding-box;
    border-radius: 42px 80px 80px 40px;
    padding: 20px;
}
.career-section-three .item h4{
    font-size: 28px;
    line-height: 20px;
    font-weight: 600;
    color: var(--font-one);
}
.career-section-three .item p{
    color:var(--font-one);
    text-align: left;
    font-size: 18px;
    line-height: 28px;
    font-weight: 300;
    opacity: 0.76;
    min-height: 112px;
}
.career-section-three .item a{
    color:var(--primary);
    text-align: left;
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
}
.career-section-three .item a:hover{
    text-decoration: underline !important;
}
.career-section-three .item p img{
    padding-right: 5px;
}

.career-section-three .slick-prev {
    z-index: 9999;
    opacity: 1;
    /* background: #ffffff17; */
    height: 90%;
    width: 205px;
    left: 0;
    background: transparent linear-gradient(90deg, #001D38 0%, #001D3900 100%) 0% 0% no-repeat padding-box;
}
.career-section-three .slick-next {
    z-index: 9999;
    opacity: 1;
    /* background: #ffffff17; */
    height: 90%;
    width: 205px;
    right: 0;
    background: transparent linear-gradient(270deg, #00305C 0%, #001D3900 100%) 0% 0% no-repeat padding-box;
}

.career-section-three .slick-track .slick-slide.slick-active:first-child,.section-three .slick-slide.slick-active:last-child{
    opacity: 0.5;
}





.career-section-four{
    margin-top: -190px;
    position: relative;
    padding-bottom: 0px;
    background: transparent linear-gradient(182deg, #133C6D 0%, #040F35 47%, #000428 59%, #000428 100%) 0% 0% no-repeat padding-box;
    border-radius: 79px 192px 79px 79px;
}

.career-section-four .items{
    max-width: 1055px;
    margin: 0 auto;
    gap: 140px;
    row-gap: 70px;
    display: flex;
    row-gap: 100px;
    justify-content: center;
    margin-top: 60px;
    flex-wrap: wrap;
}

.career-section-four .items .item{
    width: 224px;
    height: 225px;
    background: #000428;
    border-radius: 40px 74px 40px 40px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    transition: 30ms all;
}
.career-section-four .items .item img{
    max-width: 100%;
    width: 80px;
    margin: 0 auto;
}

.career-section-four .items .item p.title{
    text-align: center;
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    color:var(--font-one);
}
.career-section-four .items .item p.content{
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    color:var(--font-one);
    text-align: center;
    display: none;
    transition: 100ms all;
}
.career-section-four .items .item:hover{
    transform: scale(1.4);
    padding: 20px;
    gap: 10px;
}
.career-section-four .items .item:hover p.title{
    font-size: 16px;
    color: var(--primary);
}

.career-section-four .items .item:hover p.content{
    display: block;
    font-size: 12px;
    line-height: 16px;
}

.career-section-four .join-us{
    font-size: 28px;
    line-height: 30px;
    color: #FFFFFF;
    text-align: center;
    margin-top: 70px;
}
.career-section-four .join-us a{
    color:#FFFFFF;
    transition: 0.3s all;
}
.career-section-four .join-us a:hover{
    color:var(--primary);
}





.career-section-five{
    max-width: 1207px;
    margin:0 auto;
    background: transparent linear-gradient(186deg, #133C6D 0%, #000428 59%, #000428 100%) 0% 0% no-repeat padding-box;
    border-radius: 50px 50px 0px 0px;
    margin-top: 70px;
    padding-bottom: 180px;
}
.career-section-five .section-heading span{
    color: #01B156;
}

.career-section-five .star-heading{
    font-size: 55px;
    line-height: 73px;
    font-weight: 700;
    justify-content: center;
    color: #01B156;
    display: flex;
    flex-wrap: wrap;
}
.career-section-five .star-heading ul {
    list-style: none;
    display: flex;
    justify-content: center;
    gap: 10px;
}
.career-section-five .star-heading ul li{
    list-style: none;
}
.career-section-five .star-heading ul li i{
    color: #01B156;
}


.career-section-five .circles{
    margin-top: 40px;
    display: flex;
    gap: 40px;
    flex-wrap: wrap;
    justify-content: space-around;
}
.career-section-five .circle{
    display: flex;
    width: 180px;
    flex-direction: column;
    gap: 10px;
    text-align: center;
}

.career-section-five .circle h4{
    font-size: 20px;
    line-height: 28px;
    color: #FFFFFF;
    font-weight: 600;
}

.career-section-five .CircularProgressbar {
    width: 160px;
    vertical-align: middle;
    margin: 0 auto;
}
.career-section-five .CircularProgressbar .CircularProgressbar-text{
    font-size: 28px;
    font-weight: 700;
    fill: var(--primary) !important;
}



.career-section-five .join-us{
    font-size: 28px;
    line-height: 30px;
    color: #FFFFFF;
    text-align: center;
    margin-top: 70px;
}
.career-section-five .join-us a{
    color:#FFFFFF;
    transition: 0.3s all;
}
.career-section-five .join-us a:hover{
    color:var(--primary);
}






.career-section-six{
    background: transparent linear-gradient(184deg, #133C6D 0%, #000428 59%, #000428 100%) 0% 0% no-repeat padding-box;
    border-radius: 40px 100px 40px 40px;
    position: relative;
    margin-top: -80px;
    padding-top: 95px;
    padding-bottom: 168px;
}

.career-section-six .adv{
    background: transparent linear-gradient(181deg, #FF9700 0%, #FDD851 100%) 0% 0% no-repeat padding-box;
    border-radius: 40px 40px 0px 0px;
    max-width: 1004px;
    height: 266px;
    font-size: 20px;
    line-height: 28px;
    font-weight: 300;
    margin:0 auto;
    display: flex;
    position: relative;
    margin-top: 130px;
}
.career-section-six .adv .content{
    max-width: 500px;
    margin-left: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.career-section-six .adv h4{
    font-size: 52px;
    line-height: 58px;
    font-weight: 700;
    color: #000C4A;
}

.career-section-six .adv p{
    font-size: 20px;
    line-height: 28px;
    font-weight: 300;
    color: #000C4A;
}
.career-section-six .adv a{
    text-decoration: underline;
    font-size: 24px;
    line-height: 28px;
    font-weight: 600;
    color: #000C4A;
}

.career-section-six .adv img{
    position: absolute;
    right: 0;
    max-width: 40%;
    bottom: -30px;
}





@media (max-width: 1050px) {
    .career-section-five .circles{
        justify-content:center;
        gap: 20px;
    }
    .career-section-three .slick-prev,.career-section-three .slick-next{
        width: auto;
    }
    .career-section-six .adv h4 {
        font-size: 42px;
        line-height: 48px;
    }
}
@media (max-width: 768px) {
    .career-section-five .circle{
        width:140px;
    }
    .career-section-five .CircularProgressbar{
        width: auto;
    }
    .career-section-six .adv h4 {
        font-size: 32px;
        line-height: 38px;
    }
    .career-section-six .adv img{
        max-width: 30%;
        position: unset;
        align-self: center;
    }
    .career-section-four .items{
        gap: 70px;
        row-gap: 70px;
    }
}

@media (max-width: 500px) {
    .career-section-five .circle{
        width:122px;
    }
    .career-section-five .CircularProgressbar{
        width: auto;
    }
    .career-section-two .slider2  .slick-center .box-slider {
        -ms-transform: scale(1);
        transform: scale(1);
        max-width: 100%;
    }
    .career-section-two .transform {
        -ms-transform: scale(1);
        transform: scale(1);
    }
    .career-section-three .item{
        flex-direction: column;
        justify-content: center;
    }
    .career-section-three .item .img{
        display: flex;
        justify-content: center;
    }
    
}

@media (max-width: 440px) {
    .career-section-five .circle{
        width:222px;
    }
}



@media (max-width: 575px) {

    .career-section-two .slider2 .slick-slider .slick-track, .career-section-two .slider2 .slick-slider .slick-list {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }
    .career-section-three{
        padding-top: 0px;
        padding-bottom: 0px;
    }


    .career-section-five .star-heading{
        font-size: 32px;
        line-height: 35px;
    }
}
