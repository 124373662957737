
.bg-light{
    background: transparent linear-gradient(180deg, #133C6D 0%, #000428 64%, #000428 100%) 0% 0% no-repeat padding-box;
}
.bg-dark{
    background: transparent linear-gradient(199deg, #133C6D 0%, #000428 29%, #000428 100%) 0% 0% no-repeat padding-box;
}
.bg3{
    background: transparent linear-gradient(183deg, #00315D 0%, #000428 50%, #001B33 100%) 0% 0% no-repeat padding-box;
}





.brands-section-one{
    background: none;
    height:161px;
    position: relative;
    margin-top: -150px;
    z-index: 1;
}
.brands-section-one .items{
    width: 1004px;
    /* overflow-x: scroll; */
    max-width: 100%;
    background: #D8E8FF 0% 0% no-repeat padding-box;
    border-radius: 20px;
    margin: 0 auto;
    display: flex;
    gap: 25px;
    justify-content: center;
    height: 161px;
    align-items: center;
}
.brands-section-one .items .item{
    width: 295px;
    height: 96px;
    display: flex;
    gap: 15px;
    max-width: 100%;
}

.brands-section-one .items .item .leftbox{
    background: #F7FAFD 0% 0% no-repeat padding-box;
    border-radius: 13px 27px 14px 14px;
    width: 100px;
    align-self: center;
    text-align: center;
    padding: 15px;
    height: 95px;
}

.brands-section-one .items .item .rightbox{
    align-self: center;
}
.brands-section-one .items .item .rightbox h4{
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #004B8D;
    text-align: left;
    margin:0;
}
.brands-section-one .items .item .rightbox span{
    text-align: left;
    font-size: 40px;
    line-height: 44px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #000C4A;
}




.brands-section-two{
    box-shadow: 0px 3px 6px #00000029;
    border-radius:79px 79px 0 0;
    position: relative;
    margin-top: -80px;
    padding-top: 55px;
    padding-bottom: 200px;
}

.brands-section-two .section{
    position: relative;
    top: -25px;
    width: 964px;
    margin: 0 auto;
    border-radius: 86px 106px 0 0;
    padding-bottom: 68px;
}


.sucess-story-top345{
    top:-345px
}





.brands-section-two .items{
    display: flex;
    width: 100%;
    /* max-width: 700px; */
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    gap: 60px;
    row-gap: 60px;
    margin-top: 40px;
    margin-bottom: 70px;
}

.brands-section-two .items .item{
    text-align: center;
    align-self: baseline;
    max-width:188px;
    height:auto;
    /* cursor:pointer; */
}
.brands-section-two .items .item img{
    width: 100%;
    max-width: 100%;
    border-radius: 15px;
    /* filter:grayscale(100%); */
}
.brands-section-two .items .item:hover img{
    filter: none;
    /* cursor:pointer; */
}
.brands-section-two .items .item p{
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0px;
    color: var(--primary);
    margin-top: 15px;
}

.brands-section-three{
    background: transparent linear-gradient(201deg, #133C6D 0%, #000428 57%, #001B33 100%) 0% 0% no-repeat padding-box;
    border-radius: 40px 100px 40px 40px;
    margin-top: -95px;
    position: relative;
    padding-bottom: 200px;

}
.brands-section-three .section-heading{
    max-width: 600px;
    margin: 0 auto;
}

.brand-marginTop160{   
    margin-top: -160px;
}



@media (max-width: 960px) {
    .brands-section-one .items{
        row-gap: 25px;
        padding-left: 10px;
    }
    .brands-section-one .items .item .rightbox span{
        font-size: 30px;
    }
}


@media (max-width: 769px) {
    .brands-section-two .items{
        row-gap: 30px;
        margin-bottom: 30px;
    }
}
@media (max-width: 500px) {
    .brands-section-two .items{
        gap:20px;
        row-gap: 30px;
        margin-bottom: 30px;
    }
}
@media (max-width: 460px) {
    .brands-section-two .items .item{
        max-width: 46%;
    }
}




@media (max-width: 775px) {
    .brands-section-one{
        height: auto;
    }
    .brands-section-one .items{
        padding-left: 0px;
        flex-direction: column;
        height: auto;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 20px;
        padding-right: 20px;
    }
}