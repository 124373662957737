.terms-backg {
    background: transparent linear-gradient(43deg, #001B34 0%, #000428 36%, #133C6D 100%) 0% 0% no-repeat padding-box;
    padding-top: 112px;
    padding-bottom: 165px;
    margin-top: -72px;
}
.terms-section{
    
        max-width:1050px;
        margin: 0 auto;
        display: flex;
        gap: 30px;
        row-gap: 30px;
        flex-direction: column;
        padding: 30px 15px;
    
}
.terms-section .item{
    padding: 10px 0;
    
}
.terms-section .item h4{
    font-size: 36px;
    font-weight: 900;
    margin-bottom: 30px;
    margin-top: 30px;
    letter-spacing: 1.5px;
}
.terms-section .item span {
    color: var(--primary);
}

.terms-section .item p{
    font-size: 20px;
    line-height: 35px;
}