.cookie-backg {
    background: transparent linear-gradient(43deg, #001B34 0%, #000428 36%, #133C6D 100%) 0% 0% no-repeat padding-box;
    padding-top: 112px;
    padding-bottom: 165px;
    margin-top: -72px;
}
.cookie-backg .section-header h3{
    font-family: var(--font-default);
    color: var(--font-one);
    font-size: 64px;
    text-align: center;
    line-height: 58px;
    font-weight: 700;
    text-transform: none;
    letter-spacing: 0;
    margin-bottom: 20px;
}
.cookie-backg .section-header p{
    color: var(--font-one);
    text-align: center;
    font-size: 26px;
    line-height: 32px;
    font-weight: 500;
}

.terms-section{
    
        max-width:1050px;
        margin: 0 auto;
        display: flex;
        gap: 30px;
        row-gap: 30px;
        flex-direction: column;
        padding: 30px 15px;
    
}
.terms-section .item{
    padding: 10px 0;
    
}
.cookie-backg .terms-section .item h4{
    font-size: 40px;
    font-weight: 900;
    margin-bottom: 30px;
    margin-top: 30px;
    letter-spacing: 1.5px;
    text-align: center;
}
.terms-section .item span {
    color: var(--primary);
}

.terms-section .item p{
    font-size: 20px;
    line-height: 35px;
    text-align: justify;
}
.terms-section  .link-text {
    color: #00e4ff!important;
}
.terms-section  .cookiefont{
    font-style: italic;
    color: #fff!important;
    font-weight: 500;
}