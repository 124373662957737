.banner-five{
    min-height: auto;
    padding-top: 112px;
    padding-bottom: 300px;
    margin-top: -72px;
    background: var(--primary) url("./bg1.png");
    background-size: cover;
}

.banner-five .fixed-right-img{
    position: absolute;
    right: -8px;
    top: 100px;
}
.banner-five .fixed-right-img img{
    max-width: 100% !important;
}
.banner-five h1 {
    margin-bottom: 20px !important;
}

.banner-five .banner-search{
    width: 392px;
    height: 47px;
    margin-top: 40px;
    position: relative;
    max-width: 100%;
    opacity: .75;
    transition: 0.3;
}
.banner-five .banner-search:hover{
    opacity: 1;
}
.banner-five .banner-search i{
    position: absolute;
    top:17px;
    left:17px;
    color: black;
}

.banner-five .banner-search input{
    background: #E8F2FF 0% 0% no-repeat padding-box;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    border: 0;
    padding-left: 45px;
}

.banner-five .banner-search input:focus{
    border:0;
}

@media (max-width: 767px) {
    .banner-five{
        min-height: auto;
    }
    .banner-five .fixed-right-img{
        position:unset;
        top:unset;
        right: unset;
        margin-bottom: 40px;
    }
  }