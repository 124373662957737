.grievance-policy{
    background: transparent linear-gradient(43deg, #001B34 0%, #000428 36%, #133C6D 100%) 0% 0% no-repeat padding-box;
    padding-top: 150px;
    padding-bottom: 165px;
    margin-top: -72px;
}
.grievance-policy .section-header h3{
    font-family: var(--font-default);
    color: var(--font-one);
    font-size: 60px;
    text-align: left;
    line-height: 58px;
    margin-top: 10px;
    text-transform: none;
    letter-spacing: 0;
    font-weight: 600;
}
.grievance-policy .section-header p{
    color: var(--font-one);
    text-align: left;
    font-size: 15px;
    line-height: 24px;
    
}

.grievance-policy .terms-section{
    
        max-width:1050px;
        margin: 0 auto;
        display: flex;
        gap: 30px;
        row-gap: 30px;
        flex-direction: column;
        padding: 30px 15px;
    
}
.grievance-policy .terms-section .item{
    padding: 10px 0;
    
}
.grievance-policy.terms-section .item h4{
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 30px;
    margin-top: 30px;
    letter-spacing: 1.5px;
    text-align: left;
}
.grievance-policy .terms-section .item span {
    color: var(--primary);
}

.grievance-policy .terms-section .item p{
    font-size: 18px;
    line-height: 35px;
    text-align: left;
}
.grievance-policy .link-text {
    color: #00e4ff!important;
}
.grievance-policy .terms-section  .cookiefont{
    font-style: italic;
    color: #fff!important;
    font-weight: 500;
}

.grievance-policy .leftsideb{
    background: #013c70;
    border-radius: 2px;
    margin: 0 0 0 130px;
    width: 760px;
}
.grievance-policy .rightsideb{
    background: #013c70;
    border-radius: 2px;
    margin: 0 130px 0 0;
    padding: 30px;
    
} 
.grievance-policy .subheading{
    color: #edce4d;
    font-weight: 700;
    
}
.grievance-policy .margin-r20{
    margin-right: 20px;
    color: #fff!important;
}
.grievance-policy .process-flow img{
    max-width: 100%;
}
@media (max-width: 800px){
    .grievance-policy{
        flex-direction: column-reverse!important;
        width: 100%;
    }
    .grievance-policy .leftsideb{
        background: transparent;
    border-radius: 2px;
    margin: 0 ;
    }
    .rightsideb {
        background: #013c70;
        border-radius: 2px;
        margin: 0;
        padding: 30px;
    }
    .grievance-policy .section-header h3{
        font-size: 40px;
        line-height: 45px;
    }
    .terms-section .item h4{

    }
}
.grievance-policy .rightbox table{
    margin-top: 20px;
    border-collapse: collapse;
    width: 100%;
}
.grievance-policy .rightbox h4{
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 30px;
    margin-top: 30px;
    letter-spacing: 1.5px;
    text-align: left;
}
.grievance-policy .rightbox table th,td{
    border: 1px solid #ddd;
    text-align: left;
    padding-left: 8px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.grievance-policy .leftbox img{
    
    margin-bottom: 50px;
    margin-top: 50px;
    
}
.grievance-policy .rightsideb p{
    font-size: 15px;
    margin-bottom: 20px!important;
    text-decoration: underline;
}
.grievance-policy .annexure-process .rightbox p{
    margin-bottom: 20px!important;
}
.grievance-policy .annexure-process .rightbox img{
    max-width: 100%;
}

.grievance-policy .stickysidebar{
    position: sticky;
    height: fit-content;
    top: 0;
}
.grievance-policy .tcolor{
    color: #edce4d;
}
.grievance-policy .fsize{
    font-size: 20px;
}
.grievance-policy .fsize img{
    margin-top: 20px;

}
.grievance-policy .arowalign{
    display: flex;
}
.grievance-policy .margin-l30 {
    margin-left: 30px;
}
.grievance-policy .test-arrow li{
    font-size: 18px;
}
.grievance-adv a{
    color: #fff;
}
.grievance-policy .table-button {
   
    margin-top: 50vh;
    background: #013c70;
    padding: 15px 0;
    border-radius: 2px;
    width: 70%;
}
.grievance-policy .table-button p{
   text-align: center;
   color: #fff;
}