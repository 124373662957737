.success-story-section-one{
    background: none;
    height:161px;
    position: relative;
    top: -150px;
    z-index: 1;
}
.success-story-section-one .items{
    width: 1004px;
    /* overflow-x: scroll; */
    max-width: 100%;
    background: #D8E8FF 0% 0% no-repeat padding-box;
    border-radius: 20px;
    margin: 0 auto;
    display: flex;
    gap: 25px;
    justify-content: center;
    height: 161px;
    align-items: center;
}
.success-story-section-one .items .item{
    width: 295px;
    height: 96px;
    display: flex;
    gap: 15px;
}

.success-story-section-one .items .item .leftbox{
    background: #F7FAFD 0% 0% no-repeat padding-box;
    border-radius: 13px 27px 14px 14px;
    width: 100px;
    align-self: center;
    text-align: center;
    padding: 15px;
    height: 95px;
}

.success-story-section-one .items .item .rightbox{
    align-self: center;
}
.success-story-section-one .items .item .rightbox h4{
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #004B8D;
    text-align: left;
    margin:0;
}
.success-story-section-one .items .item .rightbox span{
    text-align: left;
    font-size: 40px;
    line-height: 44px;
    font-weight: 300;
    letter-spacing: 0px;
    color: #000C4A;
}




.success-story-section-two{
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 79px 79px 0 0;
    position: relative;
    margin-top: -120px;
    padding-top: 55px;
    padding-bottom: 200px;
}

.success-story-section-two .section{
    position: relative;
    top: -25px;
    width: 964px;
    margin: 0 auto;
    border-radius: 86px 106px 0 0;
    padding-bottom: 68px;
}


.sucess-story-top345{
    top:-345px
}


.success-story-section-two .items{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    gap: 60px;
    row-gap: 60px;
    margin-top: 40px;
    margin-bottom: 70px;
}

.success-story-section-two .items .item{
    text-align: left;
    align-self: baseline;
    max-width: 476px;
    height:auto;
    cursor:pointer;
}
.success-story-section-two .items .item img{
    width: 100%;
    border-radius: 15px;
}
.success-story-section-two .items .item:hover img{
    cursor:pointer;
}
.success-story-section-two .items .item p.category{
    text-align: left;
    font-size: 12px;
    font-weight: 300;
    line-height: 22px;
    color: #F9D6A3;
    margin-top: 10px;
}
.success-story-section-two .items .item p.category span{
    color: var(--font-one);
}
.success-story-section-two .items .item p.title{
    text-align: left;
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0px;
    color: var(--font-one);
    margin-top: 5px;
    margin-bottom: 5px !important;
}
.success-story-section-two .items .item p.title span{
    color: var(--primary);
}

.success-story-section-two .items .item  a{
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    color: #fff;
    padding-left: 0px;
}
.success-story-section-two .items .item  a:hover{
    color: var(--primary);
}














.success-story-details-one{
    background: #08122E;
    padding-top: 112px;
    padding-bottom: 110px;
    margin-top: -72px;
}

.success-story-details-one .container{
    max-width: 1060px;
    margin: 0 auto;
    padding: 30px;
    /* background: transparent linear-gradient(0deg, #001B34 0%, #000428 0%, #000428 38%, #133C6D 100%) 0% 0% no-repeat padding-box;
    border-radius: 46px 40px 46px 46px;
    padding: 98px; */
}

.success-story-details-one p.category{
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    color: var(--primary);
    margin-bottom: 5px !important;
}
.success-story-details-one p.category span{
    color: var(--font-one);
}

.success-story-details-one p.short-description{
    font-size: 22px;
    font-weight: 300;
    line-height: 34px;
    color: #F9D6A3;
}


.success-story-details-one .featured-image{
    max-width: 100%;
    border-radius: 20px;
    margin-bottom: 40px;
    margin-top: 20px;
}
.success-story-details-one h1{
    font-size: 40px;
    font-weight: 700;
    line-height: 58px;
    letter-spacing: 0px;
    text-transform: capitalize;
    color: var(--font-one);
}
.success-story-details-one h2{
    font-size: 36px;
    font-weight: 600;
    line-height: 44px;
    text-transform: capitalize;
    color: var(--font-one);
    padding-top: 10px;
    margin-bottom: 20px;
}
.success-story-details-one h1 span,.success-story-details-one h2 span, .success-story-details-one p span,
.success-story-details-one h1 strong,.success-story-details-one h2 strong, .success-story-details-one p strong{
    color: var(--primary);
    font-weight: inherit;
}


.success-story-rightpoints{
    background: #DAEAFF;
    padding: 30px;
    color: #000428;
    border-radius: 15px;
    height: auto;
    margin-top: 12px;
    transition: 0.3s all;
    /* position: sticky;
    top: 0; */
}
.success-story-rightpoints.fixed{
    position: fixed;
    top:15%;
    z-index: 999;
}
.success-story-rightpoints h5{
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
}
.success-story-rightpoints ul{
    margin-left: 0;
    padding-left: 15px;
}
.success-story-rightpoints ul li{
    list-style: none;
    margin-bottom: 10px;
}
.success-story-rightpoints ul li p{
    font-size: 16px;
    line-height: 20px;
    font-weight: 300;
}
.success-story-rightpoints ul li p strong{
    font-weight: 600;
    display: block;
    margin-bottom: 8px;
}





.success-story-details-two{
    background: transparent linear-gradient(27deg, #000428 0%, #000428 59%, #133C6D 100%) 0% 0% no-repeat padding-box;
    border-radius: 46px 140px 46px 46px;
    padding-top: 30px;
    padding-bottom: 110px;
    margin-top: -134px;
}

.success-story-details-two .container{
    max-width: 1060px;
    margin: 0 auto;
    padding: 5px 10px;
    padding-bottom: 20px;
}
.success-story-content img{
    max-width: 100%;
    height: auto;
}

.success-story-content h1{
        font-size: 40px;
        font-weight: 700;
        line-height: 58px;
}
.success-story-content h2{
    font-size: 36px;
    font-weight: 600;
    line-height: 44px;
}
.success-story-content h3{
    font-size: 32px;
    font-weight: 600;
    line-height: 44px;
}

.success-story-content h6{
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
}
.success-story-content h1 span,.success-story-content h2 span,.success-story-content h3 span, .success-story-content h6 span, .success-story-content p span,
.success-story-content h1 strong,.success-story-content h2 strong,.success-story-content h3 strong,.success-story-content h6 strong, .success-story-content p strong{
    color: var(--primary);
    font-weight: inherit;
}

.success-story-content h4{
    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
    color: var(--primary);
    margin-bottom: 20px;
}



.success-story-content h1 {
    font-weight: 700;
    font-size: 40px;
    line-height: 1.5;
}

.success-story-content h2 {
    font-weight: 700;
    font-size: 35px;
    line-height: 1.5;
}
.success-story-content h3 {
    font-weight: 700;
    font-size: 30px;
    line-height: 1.5;
}

.success-story-content  h4 {
    font-weight: 700;
    font-size: 25px;
    line-height: 1.5;
}

.success-story-content h5 {
    font-weight: 700;
    font-size: 20px;
    line-height: 1.5;
}

.success-story-content h6 {
    font-weight: 700;
    font-size: 16px;
    line-height: 1.5;
}



.success-story-content p, .success-story-content a{
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 20px !important;
}
.success-story-content a{
    color: var(--primary);
    text-decoration: underline !important;
}


.success-story-content .items{
    display: flex;
    justify-content: space-between;
    gap: 10px;
    row-gap: 20px;
}
.success-story-content .items .item{
    text-align: left;
}
.success-story-content .items .item img{
    max-width: 100%;
}
.success-story-content .items .item h6{
    color:var(--primary);
    margin-top: 10px;
    margin-bottom: 10px;
}

.success-story-content .note{
    border-left: 4px solid #FFB31D;
    padding-left: 20px;
    margin-top: 40px;
    margin-bottom: 40px;
}

.success-story-content .quote{
    position: relative;
    padding-left: 38px;
    padding-right: 38px;
}

.success-story-content .quote::before{
    background-image:url("../../../public/assets/images/about/quote-left.png");
    width: 40px;
    height: 40px;
    content: "";
    display: block;
    top: -5px;
    position: absolute;
    left: 0;
    background-size: 40px 40px;
}

.success-story-content .quote::after{
    background-image:url("../../../public/assets/images/about/quote-right.png");
    width: 40px;
    height: 40px;
    content: "";
    display: block;
    right: 0px;
    bottom: 0px;
    position: absolute;
    background-size: 40px 40px;
}


.success-story-content ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    margin-bottom: 40px;
}
.success-story-content ul li{
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    color: #FFFFFF;
    background: #002D56;
    border-radius: 10px 35px 10px 10px; 
    margin-bottom: 10px;
    padding: 10px 20px;
}  
.success-story-content ul li ul li, .success-story-content .note ul li{
    font-size: 16px;
    font-weight: 300;
    line-height: 28px;
    color: #FFFFFF;
    margin-bottom: 4px;
    margin-top: 4px;
    padding: 0;
    padding-left: 30px;
    background: transparent;
}  

.success-story-content ul li ul li::before, .success-story-content .note ul li::before {
    content: "\2022";
    color: var(--primary);
    font-weight: bold;
    display: inline-block;
    width: 6px;
    height: 6px;
    margin-left: 0;
    padding-right: 12px;
}

.success-story-content ol {
    list-style: auto;
    margin-left: 0;
    padding-left: 0;
}
.success-story-content ol li{
    font-size: 28px;
    font-weight: 600;
    line-height: 42px;
    color: #FFFFFF;
    margin-left: 28px;
    margin-bottom: 20px;
}  
.success-story-content ol li p{
    margin-top: 10px;
    margin-bottom: 10px !important;
}





.success-story-content ul.social{
    list-style:none;
    display: flex;
    gap: 15px;
    margin: 0;
}
.success-story-content ul.social li{
    list-style: none;
    font-size: 22px;
    padding: 0;
    line-height: 1;
    cursor: pointer;
    align-self: center;
}
.success-story-content ul.social li::before{
    content: '';
    padding: 0;
}
.success-story-content ul.social li i{
    transition: 0.3ms;
}
.success-story-content ul.social li:hover i{
    color: var(--primary);
}


.success-story-content ul.dot-box{
    display: flex;
    gap: 20px;
    row-gap: 20px;
    flex-wrap: wrap;
}

.success-story-content ul.dot-box li{
    border: 2px dashed #F89B15;
    border-radius: 20px;
    min-height: 155px;
    max-width: 172px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}


.success-story-share{
    background: #C8DFFF 0% 0% no-repeat padding-box;
    border-radius: 10px;
    height: 67px;
    display: flex;
    justify-content: center;
    column-gap: 30px;
    margin-top: 50px;
    margin-bottom: 50px;
}
.success-story-share p{
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    color: #000C4A;
    line-height:67px;
}

.success-story-share ul.social li, .success-story-share ul.social li a {
    color: #000C4A;
    box-sizing: border-box;
    background: transparent;
    margin-bottom: 0;
}
.success-story-share ul.social li,.success-story-share ul.social li a{
    /* color: var(--font-one); */
}
.blog-content ul.social li:hover i{
    color: var(--primary);
}






@media (max-width: 769px) {
    .success-story-section-two .items{
        row-gap: 30px;
        margin-bottom: 30px;
    }
    .success-story-content .items{
        flex-direction: column;
        justify-content:center;
    }
    .success-story-content .items .item{
        text-align: center;
    }
    .success-story-content ul.dot-box{
        justify-content:center;
    }

    .success-story-rightpoints{
        margin-bottom: 20px;
    }
    .success-story-rightpoints.fixed{
        position: unset;
        top:unset;
        z-index: unset;
       
    }
    .success-story-details-two .container{
        flex-direction: column-reverse;
    }
}



@media (max-width: 900px) {
    .success-story-content{
        max-width:100%;
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media (max-width: 600px) {
    .success-story-share{
        height: auto;
        flex-wrap: wrap;
        padding: 10px;
    }
    .success-story-share p {
        line-height: 24px;
    }

}




@media (max-width: 575px) {
    .success-story-section-two{
        padding-top: 0px;
    }
}
