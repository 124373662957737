


.news-section-one{
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 79px 79px 0 0;
    position: relative;
    margin-top: -120px;
    padding-top: 80px;
    padding-bottom: 200px;
}

.news-section-one .tab-one{
    max-width: 1122px;
}

.news-section-one .section{
    position: relative;
    top: -25px;
    width: 964px;
    margin: 0 auto;
    border-radius: 86px 106px 0 0;
    padding-bottom: 68px;
}


.news-section-one .items{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    gap: 30px;
    row-gap: 30px;
    margin-top: 40px;
    margin-bottom: 70px;
}


.news-section-one .items .item{
    text-align: left;
    align-self: baseline;
    max-width: 353px;
    height:auto;
    cursor:pointer;
}
.news-section-one .items .item .img-wrapper{
    width: 100%;
    height: 232px;
    border: 2px solid #FFFFFF;
    border-radius: 80px 20px 20px 20px;
    align-items: center;
    display: flex;
    justify-content: center;
}
.news-section-one .items .item img{
    width: auto;
    border-radius: 15px;
    /* filter:grayscale(100%); */
}
.news-section-one .items .item:hover img{
    filter: none;
    cursor:pointer;
}
.news-section-one .items .item p.category{
    text-align: left;
    font-size: 12px;
    font-weight: 300;
    line-height: 22px;
    color: #F9D6A3;
    margin-top: 10px;
    text-transform: uppercase;
}
.news-section-one .items .item p.category span{
    color: var(--font-one);
}
.news-section-one .items .item p.title{
    text-align: left;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0px;
    color: var(--font-one);
    margin-top: 5px;
    margin-bottom: 5px !important;
}
.news-section-one .items .item p.title span{
    color: var(--primary);
}

.news-section-one .items .item  a{
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    color: #fff;
    padding-left: 0px;
}
.news-section-one .items .item  a:hover{
    color: var(--primary);
}


@media (max-width: 769px) {
    .news-section-one .items{
        row-gap: 30px;
        margin-bottom: 30px;
    }
}