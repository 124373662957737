.banner-video{
  padding-bottom: 0px;
  /* min-height: 100vh; */
  min-height: 65vh;
}
.banner-video .heading{
    display: flex;
    flex-direction: column;
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
}
.banner-video .heading h1{
    font-size: 72px;
    line-height: 72px;
    font-weight: 700;
    color: #000C4A;
}
.banner-video .heading p{
    padding-top: 8px;
    font-size: 32px;
    line-height: 42px;
    font-weight: 600;
    color: #001B33;
}
.banner-video .heading p span{
    color: #194C87;
}

.banner-video .video-box{
    max-width: 800px;
    height: auto;
    margin: 0 auto;
    margin-top: 40px;
    position: relative;
    z-index: 9;
}
.banner-video .video-box .thumbnail{
    max-width: 800px;
    position: relative;
    text-align: center;
}

.banner-video .video-box .thumbnail img{
    width: 100%;
    background: #000000;
    border-radius: 60px 60px 60px 155px;
}

.banner-video .video-box .play-icon{
    position: absolute;
    width:20%;
    max-width:100px;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 42%;
    opacity: 0.75;
    transition: all 500ms;
    z-index:9;
    cursor: pointer;
}
.banner-video .video-box .play-icon img{
    border: 0;
    border-radius: 0;
    max-width: 100%;
}
.banner-video .video-box:hover .play-icon{
    opacity: 1;
}

@media (max-width: 1024px) {
    .banner-video .video-box .thumbnail img{
        border-radius: 12px;
    }
    .kwikengage-banner {
        padding-bottom: 211px !important;
    }
}

@media (max-width: 1024px) {
    .banner-video{
        min-height: 100vh;
      }
}